const imageReducer = (state = {}, action) => {
    switch (action.type) {
        case 'CURRENT_IMAGE':
            return {
                currentImg: action.payload
            }
        default:
            return state
    }
}
export default imageReducer;