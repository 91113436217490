import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import SectionText from '../components/text/SectionText';
import BoldSectionText from '../components/text/BoldSectionText';
import SubSectionHeader from '../components/text/SubSectionHeader';
import SingleColumnImg from '../components/SingleColumnImg';
import Footer from '../components/Footer';
import { FiArrowLeft } from 'react-icons/fi';
import './Migrations.css';

export default function Migrations(props) {
    const backDiv = {
        paddingTop: 50,
    }
    return (
        <div className='container'>
            <div style={backDiv} className="btnDiv">
                <button className="simpleBtn" onClick={() => {props.history.goBack()}}><FiArrowLeft /> back to UX/UI </button>
            </div>
            <SectionHeader>Project</SectionHeader>
            <SectionText>Create a data migration tool to automate manual migration processes, reduce the migration dark period and enable client self-service.</SectionText>
            <SubSectionHeader paddingTop={40}>BACKGROUND</SubSectionHeader>
            <SectionText>An Entrata to Entrata migration (E2E Migration) is the process of transferring data from one Entrata environment to another.</SectionText>
            <SectionText>{'\u2022'} E2E migrations are manually migrated by employees in US and India.</SectionText>
            <SectionText>{'\u2022'} E2E migrations have a 5-7 day dark period where the property is not active, and not yet live.</SectionText>
            <SubSectionHeader paddingTop={40}>GOAL</SubSectionHeader>
            <SectionText>{'\u2022'} Reduce the dark period</SectionText>
            <SectionText>{'\u2022'} Enable client self-service</SectionText>                
            <SectionText>{'\u2022'} Automate manual data translation and validation</SectionText>
            <SubSectionHeader paddingTop={40}>MY RESPONSIBILITIES</SubSectionHeader>
            <SectionText>{'\u2022'} User Research</SectionText>
            <SectionText>{'\u2022'} Wireframing</SectionText>
            <SectionText>{'\u2022'} Prototyping</SectionText>
            <SectionText>{'\u2022'} Usability Testing</SectionText>
            <SingleColumnImg
                imgSrcOne={'Migrations/MigrationsBlur.gif'} 
                srcOneAlt={'migrations workflow ui'}
                propClass={'fullimg'}
                
            />
          
            <SectionHeader paddingTop={70}>Research</SectionHeader>
            <SectionText>User research uncovered these 4 main problems:</SectionText>
            <SectionText>
                <BoldSectionText>Inefficient processes </BoldSectionText>
                negatively impact gross margins.
            </SectionText>
            <SectionText>
                <BoldSectionText>Outdated data </BoldSectionText>
                increases downtime and requires costly data fixes.
            </SectionText>
            <SectionText>
                <BoldSectionText>Human error </BoldSectionText>
                in manual checks compromises data integrity.
            </SectionText>
            <SectionText>
                <BoldSectionText>Dependency </BoldSectionText>
                on scheduled migrations limits flexibility.
            </SectionText>
                

            <SubSectionHeader paddingTop={40}>UNDERSTANDING OUR USERS</SubSectionHeader>
            <SectionText>E2E migrations involve six stakeholders across a six-step workflow. Understanding this process was key to identifying pain points.</SectionText>
            <SingleColumnImg
                imgSrcOne={'Migrations/UserJourneyMap.png'}
                srcOneAlt={'migrations user flow'}
               propClass={'fullimg'}
            />
       
            <br></br>
            <SectionText>I conducted workshops with migration consultants, product managers, the data processing team, and operations consultants to gather user feedback at each step. Feedback was categorized into process and development issues, then prioritized based on impact. This approach helped us identify critical areas for improvement, paving the way for a more efficient and user-friendly migration experience.</SectionText>
        


            <SectionHeader paddingTop={70}>Ideate</SectionHeader>
            <SubSectionHeader paddingTop={40}>LEVERAGING BACKCASTING TO REDEFINE THE MIGRATION PROCESS</SubSectionHeader>
            <SectionText>Backcasting allowed us to break free from the constraints of the existing process and encourage innovative thinking. Starting with a blank slate, my product manager and I collaborated to envision an ideal solution, facilitating brainstorming sessions with stakeholders. Engaging in discussions about the workflow with leadership provided valuable business and customer insights that were essential for informed decision-making.</SectionText>
            <SubSectionHeader paddingTop={40}>GOALS</SubSectionHeader>
            <SectionText>{'\u2022'} Streamline manual workflows, minimizes unnecessary internal hours, and enhances communication efficiency.</SectionText>
            <SectionText>{'\u2022'} Implement a robust data transfer solution using custom SQL scripts to ensure accurate, real-time data updates without relying on reports or CSVs.</SectionText>
            <SectionText>{'\u2022'} Develop automated validation checks to eliminate manual processes, ensuring higher accuracy and reliability in system settings.</SectionText>
            <SectionText>{'\u2022'} Create a self-service migration tool that empowers clients to initiate migrations on their own timeline, improving their overall experience and satisfaction.</SectionText>

            <SingleColumnImg
                imgSrcOne={'Migrations/MigrationsBlur.gif'} 
                srcOneAlt={'migrations workflow ui'}
                propClass={'fullimg'}
                
            />
              <br></br>
        
            <SubSectionHeader paddingTop={70}>USER TESTING THEMES</SubSectionHeader>
            <SectionText>User testing uncovered 3 main themes:{'\n\r'}
            <br></br>
                <BoldSectionText>Standardize Terminology: </BoldSectionText>Align terminology with industry standards to enhance user understanding and reduce confusion.{'\n\r'}
                <br></br>
                <BoldSectionText>Establish Guide Rails for Selections: </BoldSectionText> Define contractual parameters clearly and adjust the workflow to ensure user selections align with these guidelines. {'\n\r'}
                <br></br>
                <BoldSectionText>Provide Contextual Helper Text: </BoldSectionText>Implement helper text to enhance user confidence by adding clarity, guidance, and preventing errors.{'\n\r'}
            </SectionText>


            <SectionHeader paddingTop={70}>Beta Launch</SectionHeader>
            <SectionText>Launching an internal beta was crucial for testing the tool in a controlled environment, and with real data. This approach created a secure environment for identifying strengths and weaknesses. It also enabled multiple involved parties to give feedback effectively and in a standardized way. This offered the flexibility needed to iterate on processes and workflows, ensuring that the final product met user needs and expectations.</SectionText>

            <SectionHeader paddingTop={70}>Outcomes</SectionHeader>
            <SectionText>
                <BoldSectionText>Minimized downtime by 97%: </BoldSectionText>
            Reduced the dark period from 5-7 days to just 2 hours, significantly improving client satisfaction and operational efficiency.</SectionText>
            <SectionText>
                <BoldSectionText>Increased migration capacity: </BoldSectionText>
                The new client-facing tool enabled our team to handle more migrations simultaneously, expanding service capabilities and revenue potential.</SectionText>
            <SectionText>
                <BoldSectionText>Streamlined data handling: </BoldSectionText>
                Eliminated manual data translation, saving 2-16 hours per property migration, which directly enhances productivity and reduces operational costs.</SectionText>
            <SectionText>
                <BoldSectionText>Enhanced data integrity: </BoldSectionText>
                Implementing custom SQL reduced the likelihood of data fixes, saving approximately 16 hours of back-and-forth communication per property, fostering stronger client relationships.</SectionText>
             <SectionText>
                <BoldSectionText>Improved efficiency: </BoldSectionText>
                Automated manual checks and validations, saving 1-2 hours per property migration, which allows the team to focus on higher-value tasks and improves overall workflow efficiency.</SectionText>
            
           <Footer></Footer>
        </div>
    )
}
