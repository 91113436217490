import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import SectionText from '../components/text/SectionText';
import SubSectionHeader from '../components/text/SubSectionHeader';
import TwoColumnImg from '../components/TwoColumnImg';
import SingleColumnImg from '../components/SingleColumnImg';
import Footer from '../components/Footer';
import { FiArrowLeft } from 'react-icons/fi';

export default function HonedUxUi(props) {
    const backDiv = {
        paddingTop: 50,
    }
    return (
        <div className='container'>
            <div style={backDiv} className="btnDiv">
                <button className="simpleBtn" onClick={() => {props.history.goBack()}}><FiArrowLeft /> back to UX/UI </button>
            </div>
            <SectionHeader>Project</SectionHeader>
            <SectionText>Create an app for rock climbers to customize their hangboard training. An app where they can create their own workouts (set interval timers, reps, and weight) and add exercises with premade hangboards.</SectionText>
            <TwoColumnImg
                imgSrcOne={'Honed/iPhoneMockup-VideoHangboard.gif'}
                imgSrcTwo={'Honed/iPhoneMockup-VideoTimer.gif'}
                srcOneAlt={'gif of hangboard setup.'}
                srcTwoAlt={'gif of countdown timer.'}
            />
            <SubSectionHeader paddingTop={25}>BACKGROUND</SubSectionHeader>
            <SectionText>The basic exercise of hangboarding is a dead hang from small holds. A strong climber can hang an 18mm edge with just 1 arm. Climbers use hangboarding to get over plateaus, conquer particular holds, and increase overall finger strength. Hangboarding is one of the best ways to increase grip strength and upper body power.</SectionText>
            <SubSectionHeader paddingTop={25}>IDEATION {'&'} GOALS</SubSectionHeader>
            <SectionText>{'\u2022'} We want users to find their specific hangboard in our app.</SectionText>
            <SectionText>{'\u2022'} We want users to choose a specific hold on a hangboard</SectionText>
            <SectionText>{'\u2022'} We want users to choose fingers used to hang</SectionText>
            <SectionText>{'\u2022'} We want users to create a custom hangboarding workout on/off the board</SectionText>

            <SubSectionHeader paddingTop={25}>MY RESPONSIBILITIES</SubSectionHeader>
            <SectionText>{'\u2022'} Wireframe</SectionText>
            <SectionText>{'\u2022'} UI Design</SectionText>

            <SectionHeader paddingTop={50}>Sketches</SectionHeader>
            <SectionText>With goals in mind, I began sketching out layout ideas, features, and the user flow.</SectionText>
            <SubSectionHeader paddingTop={25}>CREATE AN EXERCISE</SubSectionHeader>
            <SectionText>Users can choose from a pool of hangboards, select the holds and fingers they are training. Parameters should include reps, timers, weight.</SectionText>
            <SingleColumnImg 
                imgSrcOne={'Honed/Sketch_CreateExercise_Sketch.png'}
                srcOneAlt={'Sketch of create exercise'}
            />
            <SubSectionHeader paddingTop={50}>CREATE A WORKOUT</SubSectionHeader>
            <SectionText>Users can create their own custom workouts (a series of individual exercises). Users can label each exercise, move their order, copy or delete.</SectionText>
            <SingleColumnImg 
                imgSrcOne={'Honed/Sketch_CreateWorkout_Sketch.png'}
                srcOneAlt={'Sketch of create workout'}
            />
            <SubSectionHeader paddingTop={50}>PLAY WORKOUT</SubSectionHeader>
            <SectionText>When ‘played,’ a workout displays the holds and fingers, timer (if applicable) and shows the user what exercise is next.</SectionText>
            <SingleColumnImg 
                imgSrcOne={'Honed/Sketch_PlayWorkout_Sketch.png'}
                srcOneAlt={'Sketch of play workout'}
            />
            <SectionHeader paddingTop={50}>Design</SectionHeader>
            <SectionText>I designed Honed to be clean and minimal, with an energetic, lively hue of red. It’s modern, clear design lends itself to the functionality of the app — plain, uncomplicated, clear-cut.</SectionText>
            <SubSectionHeader paddingTop={50}>CREATE EXERCISE</SubSectionHeader>
            <SectionText>User begins by creating an exercise.</SectionText>
            <SingleColumnImg
                imgSrcOne={'Honed/Create_Exercise.png'}
                srcOneAlt={'Design of create exercise.'}
            />
            <SubSectionHeader paddingTop={25}>SELECT HANGBOARD</SubSectionHeader>
            <SectionText>In their exercise creation, this user has selected the Beastmaker 1000 hangboard, choosing the index and middle fingers to hang on the 3-finger hold.</SectionText>
            <SingleColumnImg
                imgSrcOne={'Honed/Hangboard_Hold.png'}
                srcOneAlt={'Desing of select hangboard.'}
            />
            <SubSectionHeader paddingTop={50}>CREATE WORKOUT</SubSectionHeader>
            <SectionText>A workout displays the list of exercises the user has created.</SectionText>
            <SingleColumnImg
                imgSrcOne={'Honed/Create_Workout.png'}
                srcOneAlt={'Design of select hangboard.'}
            />
            <SubSectionHeader paddingTop={50}>PLAY WORKOUT</SubSectionHeader>
            <SectionText>The individual exercise is displayed, as well the upcoming exercise.</SectionText>
            <SingleColumnImg
                imgSrcOne={'Honed/Play_Workout.png'}
                srcOneAlt={'Design of play workout screen.'}
            />
            <SubSectionHeader paddingTop={50}>TIMER</SubSectionHeader>
            <SectionText>Timer displays the current exercise countdown time.</SectionText>
            <SingleColumnImg
                imgSrcOne={'Honed/Timer.png'}
                srcOneAlt={'Design of timer screen.'}
            />
            <SectionHeader paddingTop={50}>Development</SectionHeader>
            <SectionText>I used Sketch to allow developers to work with the designs. The app was built with ReactNative. The app launched in 2020.</SectionText>

            <SectionHeader paddingTop={50}>Key Takeaway</SectionHeader>
            <SectionText>{'\u2022'} Think about the native device plays into user interaction. Understand what it looks like to set a timer in iOS vs Android.</SectionText>
            <SectionText>{'\u2022'} Because of the customized clickable areas, I learned about .svg file formats and how to best export them for developers </SectionText>
            <SectionText>{'\u2022'} Consider cost and time and maintance of app when developing an app. Understand how often will it be maintained and the costs associated with that.</SectionText>
            <SectionText>{'\u2022'} 'Feedback' button is important to have in the app - I've recieved user feedback through that feature</SectionText>

            <SectionHeader paddingTop={50}>Next Steps</SectionHeader>
            <SectionText>{'\u2022'} Maintance! I've gotten feedback on the app through the feedback button and need to implement customer feedback. There are also bugs in the code due to lack of maintenance which has been frustrating for users.  </SectionText>
            <SectionText>{'\u2022'} Usability Testing. I look forward to doing usabilty testing and revising designs based off insights from those tests.  </SectionText>
           


            <Footer></Footer>
        </div>
    )
}
