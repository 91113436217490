import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import SectionText from '../components/text/SectionText';
import BoldSectionText from '../components/text/BoldSectionText';
import SubSectionHeader from '../components/text/SubSectionHeader';
import TwoColumnImg from '../components/TwoColumnImg';
import SingleColumnImg from '../components/SingleColumnImg';
import Footer from '../components/Footer';
import { FiArrowLeft } from 'react-icons/fi';
import './ClimbingPartnerFinder.css';

export default function ClimbingPartnerFinder(props) {
    const backDiv = {
        paddingTop: 50,
    }
    return (
        <div className='container'>
            <div style={backDiv} className="btnDiv">
                <button className="simpleBtn" onClick={() => { props.history.goBack() }}><FiArrowLeft /> back to UX/UI </button>
            </div>
            <SectionHeader>Project</SectionHeader>
            <SectionText>Design a website for climbers to find rock climbing partners.</SectionText>
            <SubSectionHeader paddingTop={40}>BACKGROUND</SubSectionHeader>
            <SectionText>Most types of climbing require a partner. To find climbing partners, climbers discreetly watch other climbers in a gym setting. They keep an eye out for how climbers climb, their skill level, and their style of climbing. They first observe, and if they determine a good fit, will start a conversation with those climbers and try to climb together. This is a time consuming process. If you're not outgoing, don't climb indoors, or don't have access to a gym, this is not a viable way to find a partner.</SectionText>

        <SubSectionHeader paddingTop={40}>GOAL</SubSectionHeader>
            <SectionText>Create a website to match climbing partners together in a friendly way. Humanize the search in a way so users can visualize potential frienships. </SectionText>
            <SubSectionHeader paddingTop={40}>MY RESPONSIBILITIES</SubSectionHeader>
            <SectionText>{'\u2022'} User Research</SectionText>
            <SectionText>{'\u2022'} Wireframing</SectionText>
            <SectionText>{'\u2022'} Prototyping</SectionText>
            <SectionText>{'\u2022'} Usability Testing</SectionText>
            <SectionText>{'\u2022'} UI Design</SectionText>

            <div className="GifWrapper">
                <SingleColumnImg
                    imgSrcOne={'ClimbingPartner/Message-small.gif'}
                    srcOneAlt={'website interface design'}
                    propClass={'fullimg'}
                />
            </div>
            
            <SectionHeader paddingTop={70}>User Research</SectionHeader>
            <SectionText>I interviewed 6 rock climbers and created 2 aggregate empathy maps with common themes in their behaviors and attitudes.</SectionText>
            <SubSectionHeader paddingTop={40}>EMPATHY MAPS</SubSectionHeader>
            <TwoColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_Marley_Empathy.jpg'}
                imgSrcTwo={'ClimbingPartner/ClimbingPartner_Paulo_Empathy.jpg'}
                srcOneAlt={'Marley empathy map'}
                srcTwoAlt={'Paulo empathy map'}
            />
            <SubSectionHeader paddingTop={40}>PERSONAS</SubSectionHeader>
            <SectionText>With insights from user research and empathy mapping, I created detailed personas.</SectionText>
            <TwoColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_Marley.jpg'}
                imgSrcTwo={'ClimbingPartner/ClimbingPartner_Paulo.jpg'}
                srcOneAlt={'Marley Persona'}
                srcTwoAlt={'Paulo Persona'}
            />
            <SubSectionHeader paddingTop={40}>PAIN POINTS</SubSectionHeader>
            <SectionText>User research uncovered these 4 main problems:</SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>Individuals who want to learn climbing don't know how to find a partner.{'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create search functionality that includes 'skill level', so climbers are matched with similarly competent climbers.
            </SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>Experienced climbers who moved to a new city, or who are traveling to a climbing destination, feel it's difficult to find a climbing partner. {'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create search functionality to include types of climbing and location.
            </SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>Having a relationship or 'clicking' with someone is imperative to feeling comfortable climbing together. {'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create profile pages that humanize these users and help visualize a potential friendship.
            </SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>Logistics of scheduling, climbing preference (indoor/outdoor), and gym preference, make it difficult to find someone to climb with.{'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create robust filters to narrow down search results to realistic partner options the user is looking for.</SectionText>

          
            <SectionHeader paddingTop={70}>Ideate</SectionHeader>
            <SubSectionHeader paddingTop={40}>COMPETITIVE AUDIT</SubSectionHeader>
            <SectionText>My top competitors were Mountain Project, and Meetup. Mountain Project allows users to find rock climbing routes, photos, and guides for every state, along with experiences and advice from fellow climbers. Meetup allows users to meet new people who share your interests through online and in-person events.</SectionText>
            <TwoColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_MountainProject-Competitor.jpg'}
                imgSrcTwo={'ClimbingPartner/ClimbingPartner_Meetup-Competitor.jpg'}
                srcOneAlt={'Mountain Project website screenshot'}
                srcTwoAlt={'Meetup website screenshot'}
            />
            <SectionText>I compiled a competitive audit of 4 competitors. I listed pros and cons and gave ratings for their features, accessibility, navigation and visual design. After analyzing strengths and weaknesses of each, this gave me a good idea of gaps and opportunities for my climbing partner finder responsive website.</SectionText>

            
            <div className='LinkBtnWrapper'>
            <a className='LinkBtn' href="https://docs.google.com/spreadsheets/d/1Z8FuiBfl1u1aHyM0_PWXyeAhDzL179trmzvAE20zlSE/edit?usp=sharing&resourcekey=0-KAVONrWbe9WFzqD4cVcUPw" target="_blank" rel="noopener noreferrer"> 
            Competitive Audit
            </a>
            </div>

            <SectionText paddingTop={70}><BoldSectionText>Opportunity Gap:</BoldSectionText></SectionText>
            <SectionText>{'\u2022'} Balance tone between Mountain Project (stale, robotic, serious, intimiating) and Meetup (overly friendly, too lighthearted/inexperienced)</SectionText>
            <SectionText>{'\u2022'} Increase usability of results page with robust search and filter functionality </SectionText>
            <SectionText>{'\u2022'} Add profile content like schedule availabity, gym member information, interests, and photos to humanize the search</SectionText>
            <SectionText>{'\u2022'} Simplify messaging portal</SectionText>  
         

            <SectionHeader paddingTop={70}>Sitemap</SectionHeader>
            <SectionText>Creating a sitemap helped organize the necessary content a user needs to search for a partner in a successful, educated way. It was important to keep the pages a user needs to go through to get the information they are looking for, to a minimum. I was actively aware of scope creep, constantly reminding myself this doesn't need to be the biggest robust tool, it just needs to fulfill the needs of the user.
                </SectionText>
            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_Sitemap.jpg'}
                srcOneAlt={'climbing partner website sitemap'}
                propClass={'paperWireFrame'}
            />

            <SectionHeader paddingTop={70}>Wireframe</SectionHeader>
            <SectionText>I created 5 paper wireframes of each main screen. I then starred elements on each wireframe that I liked, and created a sixth version of my starred elements in one screen design.
</SectionText>
            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_PaperWireframe.jpg'}
                srcOneAlt={'paper wireframe sketches'}
                propClass={'paperWireFrame'}
            />

            <SectionText>I started digitizing 4 main screens from my paper wireframes. Each in desktop, tablet and mobile sizes.</SectionText>
            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_ResponsiveWireframe-A.jpg'}
                srcOneAlt={'climbing partner home responsive wireframes'}
                propClass={'fullimg'}
            />
            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_ResponsiveWireframe-B.jpg'}
                srcOneAlt={'climbing partner search results responsive wireframes'}
                propClass={'fullimg'}
            />

            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_ResponsiveWireframe-C.jpg'}
                srcOneAlt={'climbing partner user profile responsive wireframes'}
                propClass={'fullimg'}
            />

            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_ResponsiveWireframe-D.jpg'}
                srcOneAlt={'climbing partner messaging responsive wireframes'}
                propClass={'fullimg'}
            />


            <SectionHeader paddingTop={70}>Usability Study Findings</SectionHeader>
            <SectionText>I conducted 5 usability tests. Each test was held remotely and moderated. Users were asked to perfom a set of tasks with the low-fidelity prototype. I also conducted a questionaire about their experience. Below are themes and insights from the tests and questionaire.</SectionText>
            <           SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Users missed input fields completely.{'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Users need a more obvious and familiar design for input fields.
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Users were hesitant on how to view a user profile. {'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Users need a clear way to enter a users profile page.
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Users suggested additional profile page content. {'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Users wanted to know whether a user prefers to climb inside or outside, and their years of experience.
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText> Users rated profile content categories according to importance. {'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Reorganize the profile page to align with these preferences.

            </SectionText>

            <div className='LinkBtnWrapper'>
                <a className='LinkBtn' href="https://xd.adobe.com/view/651c2474-7c67-44a6-b79e-4a57cb1a48a3-304f/" target="_blank" rel="noopener noreferrer">
                    View Low-Fi Prototype
            </a>
            </div>

            <SubSectionHeader paddingTop={70}>REVISED DESIGNS</SubSectionHeader>
            <SectionText>
                <BoldSectionText>Insight: </BoldSectionText> Users need a more obvious and familiar design for input fields.</SectionText>

            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_Usability-A.jpg'}
                srcOneAlt={'before and after  usabilty study screenshot'}

            />

            <SectionText paddingTop={100}>
                <BoldSectionText>Insight: </BoldSectionText> Users need a clear way to enter a users profile page.</SectionText>

            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_Usability-B.jpg'}
                srcOneAlt={'before and after usabilty study screenshot'}

            />

            <SectionText paddingTop={100}>
                <BoldSectionText>Insight: </BoldSectionText> Added profile content and reorganize the profile page to align with user preferences.</SectionText>

            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/ClimbingPartner_Usability-C.jpg'}
                srcOneAlt={'before and after usabilty study screenshot'}

            />


            <SectionHeader paddingTop={70}>Design</SectionHeader>
            <SectionText>I wanted to create a design that felt earthy, and human. The serif and sans serif font combination adds sophistication to this youthful palette. Together, it feels welcoming and inviting.</SectionText>

            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/HomeDesign.jpg'}
                srcOneAlt={'climbing partner home screen design'}
                propClass={'fullimg'}
            />

            <div className='LinkBtnWrapper ButtonFullPadding'>
                <a className='LinkBtn' href="https://xd.adobe.com/view/83b48b05-d7d1-48eb-8acb-2fbca58b4023-d6e7/" target="_blank" rel="noopener noreferrer">
                    View High-Fi Desktop Prototype
            </a>
            </div>

            <SectionText>I began creating a stickersheet of colors, typography, icons, navigation, components, and their states.
 </SectionText>



            <SingleColumnImg
                imgSrcOne={'ClimbingPartner/Stickersheet.jpg'}
                srcOneAlt={'sticker sheet colors, fonts, typography'}
                propClass={'fullimg'}
            />




            <div className='LinkBtnWrapper'>
                <a className='LinkBtn' href="https://xd.adobe.com/view/bde16ec4-318a-4ce9-be25-9c01ff119360-e8b2/" target="_blank" rel="noopener noreferrer">
                    View High-Fi Mobile Prototype
            </a>
            </div>


            <SectionHeader paddingTop={70}>Key Takeaway</SectionHeader>
            <SectionText>{'\u2022'} A sleeker, minimal input field might look nice or save space, but if it isn't intuitive, it doesn't serve the user. User comes before aesthetics. </SectionText>
            <SectionText>{'\u2022'} Drop down menus can quickly feel clunky on mobile. Being able to see the menu items without scrolling helps mitigate disorientation. Utilizing containers and shadows can help visually enforce the relationship between field title and other nested elements. </SectionText>
            <SectionText>{'\u2022'} Cards are helpful for organization and helping the eye compartmentalize/move through content quickly </SectionText>
            <SectionText>{'\u2022'} Messaging portals can become quite complex. Keep functionality to realistic user needs. Not all functions are necessary. </SectionText>


            <SubSectionHeader paddingTop={50}>NEXT STEPS</SubSectionHeader>
            <SectionText>{'\u2022'} Conduct a HotJar or HeatMap test to see where users are spending the most time in people profiles. What can we learn from that, and how does that influence the design/layout?</SectionText>
            <SectionText>{'\u2022'} How long are people spending on the search results page? What can we learn from that — should we add/omit information from the profile cards?</SectionText>
            <SectionText>{'\u2022'} Future expansion: Would users be interested in creating groups and events? </SectionText>
            <Footer></Footer>

        </div>
    )
}
