import React, { Component } from "react";
import "./Home.css";
import { LinkContainer } from "react-router-bootstrap";
import { connect } from 'react-redux';
import { navBackground } from '../actions/navBackground'

class Home extends Component {

  componentDidMount()
  {
    document.body.style.backgroundColor = "#f1dfd4";
    this.props.navBackground(true);
  }

  componentWillUnmount()
  {
    document.body.style.backgroundColor = null;
  }

  render() {
    return (
      <div className="home">
        <div>
          <LinkContainer to="/UxUi">
            <a href="/UxUi" className="bigLink" >ux/ui</a>
          </LinkContainer>
        </div>
        <div>
          <LinkContainer to="/branding">
            <a href="/branding" className="bigLink" >branding</a>
          </LinkContainer>
        </div>
        <div>
          <LinkContainer to="/infographics">
            <a href="/infographics" className="bigLink">infographics</a>
          </LinkContainer>
        </div>
        <div>
          <LinkContainer to="/motion">
            <a href="/motion" className="bigLink">motion</a>
          </LinkContainer>
        </div>
        <div>
          <LinkContainer to="/invitations">
            <a href="/invitations" className="bigLink">invitations</a>
          </LinkContainer>
        </div>
       
      </div>
    );
  }
}

export default connect(null, {navBackground})(Home);