import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import SectionText from '../components/text/SectionText';
import BoldSectionText from '../components/text/BoldSectionText';
import SubSectionHeader from '../components/text/SubSectionHeader';
import SingleColumnImg from '../components/SingleColumnImg';
import Footer from '../components/Footer';
import { FiArrowLeft } from 'react-icons/fi';
import './Iconography.css';

export default function Iconography(props) {
    const backDiv = {
        paddingTop: 50,
    }
    return (
        <div className='container'>
            <div style={backDiv} className="btnDiv">
                <button className="simpleBtn" onClick={() => {props.history.goBack()}}><FiArrowLeft /> back to UX/UI </button>
            </div>
            <SectionHeader>Project</SectionHeader>
            <SectionText>Led a team of two designers in the comprehensive redesign of our icon library. Developed a new collection of 400+ icons and established detailed guidelines and standards for their style and usage to ensure consistency across the design system.</SectionText>
            <SubSectionHeader paddingTop={40}>MY RESPONSIBILITIES</SubSectionHeader>
            <SectionText>
               <BoldSectionText>UI Design: </BoldSectionText>Developed guidelines and styling rules for an icon set and personally created 120+ icons {'\n\r'}
               <BoldSectionText>Team lead: </BoldSectionText>Managed and coordinated a team of two designers to execute the project. {'\n\r'}
            </SectionText>
            <SingleColumnImg
                imgSrcOne={'Iconography/IconSet.png'} 
                srcOneAlt={'set of icons for amenities, media, finance, objects, symbols and people'}
                propClass={'fullimg'}
            />
          
            <SectionHeader paddingTop={70}>Challenges & Considerations</SectionHeader>
            <SectionText>Designing effective icons involves addressing several key challenges to ensure usability and visual appeal. Here are the primary factors to consider:</SectionText>
            <SectionText>
                <BoldSectionText>Design context: </BoldSectionText>Icons can be used alone, with informational text, or in buttons. Knowing the context helps determine the appropriate level of detail for each scenario.{'\n\r'}
                <BoldSectionText>Grid: </BoldSectionText>Icons are limited to a 20px grid. It was imperative that we simplify decorations, accents, and linework. {'\n\r'}
                <BoldSectionText>Scalable: </BoldSectionText>Icons must be scalable and maintain clarity at various sizes. Avoid designs that become muddy when scaled down or appear too sparse or empty at larger sizes. {'\n\r'}
            </SectionText>

            <SectionHeader paddingTop={70}>Guidelines and Standards</SectionHeader>
            <SectionText>With the challenges and considerations in mind, I developed and implemented guidelines and styling rules. This would also help ensure all icons had a cohesive and uniform appearance. This effort resulted in a unified design library with consistent standards and style, eliminating individual designer styles and creating a seamless, standardized icon set.
             </SectionText>
             <SingleColumnImg
                imgSrcOne={'Iconography/StyleGuide.png'} 
                srcOneAlt={'iconography style guide rules'}
                
            />

            <SectionHeader paddingTop={70}>Ideation & versioning</SectionHeader>
            <SectionText>Icons can use a base of four basic shapes: vertical rectangles, horizontal rectangles, squares, and circles. Since outline icons can be more challenging for the eye to interpret than filled icons, it’s crucial to explore different base shapes to minimize cognitive load. This is especially helpful when icons appear in a row (such as a navigation bar) for each have a distinct base shape.
            </SectionText>
            <SingleColumnImg
                imgSrcOne={'Iconography/Icon-base.png'}
                srcOneAlt={'4 base shapes of icons'}
                propClass={'fullimg'}
            />

            <SectionText>To maintain consistency, alignment, visual balance, and precision, these base shapes were used as a foundational grid.
            </SectionText>
            <SingleColumnImg
                imgSrcOne={'Iconography/MasterGrid.png'}
                srcOneAlt={'layers of grid shapes'}
                propClass={'fullimg'}
            />
            <SectionText>And here’s a snapshot of the grid in action.
            </SectionText>
            <SingleColumnImg
                imgSrcOne={'Iconography/Grid-in-use.png'}
                srcOneAlt={'versioning icons in the grid'}
                propClass={'fullimg'}
            />


            <SectionHeader paddingTop={70}>Exploration & user testing</SectionHeader>
            <SectionText>I conducted regular group critiques to generate objective feedback, ensure quality, and foster collaboration (because 20 px is really pushing it for a handshake with 10 fingers).
            </SectionText>
            <SingleColumnImg
                imgSrcOne={'Iconography/Exploration.png'}
                srcOneAlt={'exploration and versions of various icons'}
                propClass={'fullimg'}
            />

            <SubSectionHeader paddingTop={50}>USER TESTING</SubSectionHeader>
            <SectionText>Icons that were still ambiguous underwent user testing. First, users were shown an icon and asked to identify what it was meant to convey. Then, they viewed 3-4 variations of the same icon and selected their preferred version.
            </SectionText>
            <SingleColumnImg
                imgSrcOne={'Iconography/user-testing.png'}
                srcOneAlt={'exploration and versions of various icons'}
                propClass={'fullimg'}
            />

            <SectionText>Icons that remained uncertain after testing were placed in buttons and lists to evaluate their effectiveness in context. (Does the hot tub look like a coffee cup without supporting text?)
            </SectionText>
            <SingleColumnImg
                imgSrcOne={'Iconography/iconcontext.png'}
                srcOneAlt={'icons in buttons with and without text'}
                propClass={'fullimg'}
            />
                
          

             <SectionText>
            <SectionHeader paddingTop={70}>Key learnings</SectionHeader>
            <BoldSectionText>Longevity: </BoldSectionText>Avoided using gender-specific icons and traditional gendered family representations to ensure the icons remain relevant and inclusive over time.{'\n\r'}
            <BoldSectionText>Internationalization: </BoldSectionText>Designed icons to be universally understood, such as using a European toilet, or money without dollar signs, to accommodate diverse cultural contexts. {'\n\r'}
            <BoldSectionText>Design Precision: </BoldSectionText>Understood that even minor design elements, like decorations and linework, can significantly impact the icon’s recognizability and functionality at different sizes.{'\n\r'}
            </SectionText>

            <SubSectionHeader paddingTop={50}>NEXT STEPS</SubSectionHeader>
            <SectionText>This icon set is always expanding. I'm currently building a set for belongings covered under insurance. Think jewelry, tools, electronics, furniture—you name it. Stay tuned!</SectionText>
            
           <Footer></Footer>
        </div>
    
    )
}
