import React, { Component } from 'react'
import { connect } from 'react-redux';
import { currentImage } from '../actions/currentImage';
import { previousSubject } from '../actions/previousSubject';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Branding.css';


class Branding extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }

    viewImage(img) {
        this.props.currentImage(img);
        this.props.previousSubject("branding");
        this.props.history.push('/viewImage');
    }

    renderImage(imageUrl) {
        return (
            <div className="col-6 spacingBtm" key={imageUrl.key}>
                <div className="container">
                    <img className="imgHover image" onClick={() => this.viewImage(imageUrl.img + ".png")} alt={imageUrl.alt} src={require('../media/' + imageUrl.img + "-Thumb.png")} />
                    <div className="middleBrand">
                        <div className="text" onClick={() => this.viewImage(imageUrl.img + ".png")}>Click to see full branding suite</div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="branding top">
                <div className="title"> 
                <Dropdown>
                        <Dropdown.Toggle className="dropdownTitle">
                        BRANDING
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <LinkContainer to="/motion">
                                <Dropdown.Item>MOTION</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/invitations">
                                <Dropdown.Item>INVITATIONS</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/infographics">
                                <Dropdown.Item>INFOGRAPHICS</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/UxUi">
                                <Dropdown.Item>UI/UX</Dropdown.Item>
                            </LinkContainer>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="row">
                    {this.props.branding.map(imageUrl => this.renderImage(imageUrl))}
                </div>
            </div>
        )
    }
}

export default connect(null, { currentImage, previousSubject })(Branding);