import React, { Component } from 'react';
import { connect } from 'react-redux';
import { currentImage } from '../actions/currentImage';
import { previousSubject } from '../actions/previousSubject';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Infographics.css';

class Infographics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }



    viewImage(img) {
        this.props.currentImage(img);
        this.props.previousSubject("infographics");
        this.props.history.push('/viewImage');
    }

    renderImage(imageUrl) {
        return (
            <div className="col-6 spacingBtm" key={imageUrl.key}>
                <div className="container">
                    <img className="imgHover image" onClick={() => this.viewImage(imageUrl.img + "-INF.png")} alt={imageUrl.alt} src={require('../media/' + imageUrl.img + "-Thumb.png")} />
                    <div className="middleInfo">
                        <div className="text" onClick={() => this.viewImage(imageUrl.img + "-INF.png")}>Click to see full infographic</div>
                    </div>
                </div>
            </div>

        );
    }

    render() {
        return (
            <div className="infographics top">
                <div className="title">
                    <Dropdown>
                        <Dropdown.Toggle  className="dropdownTitle">
                            INFOGRAPHICS 
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <LinkContainer to="/branding">
                                <Dropdown.Item >BRANDING</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/motion">
                                <Dropdown.Item>MOTION</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/invitations">
                                <Dropdown.Item>INVITATIONS</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/UxUi">
                                <Dropdown.Item>UI/UX</Dropdown.Item>
                            </LinkContainer>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="row">
                    {this.props.infographics.map(imageUrl => this.renderImage(imageUrl))}
                </div>
            </div>
        )
    }
}

export default connect(null, { currentImage, previousSubject })(Infographics);

