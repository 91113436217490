import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import SectionText from '../components/text/SectionText';
import BoldSectionText from '../components/text/BoldSectionText';
import SubSectionHeader from '../components/text/SubSectionHeader';
import ThreeColumnImg from '../components/ThreeColumnImg';
import TwoColumnImg from '../components/TwoColumnImg';
import SingleColumnImg from '../components/SingleColumnImg';
import Footer from '../components/Footer';
import { FiArrowLeft } from 'react-icons/fi';
import './HomeBody.css';

export default function HomeBody(props) {
    const backDiv = {
        paddingTop: 50,
    }
    return (
        <div className='container'>
            <div style={backDiv} className="btnDiv">
                <button className="simpleBtn" onClick={() => {props.history.goBack()}}><FiArrowLeft /> back to UX/UI </button>
            </div>
            <SectionHeader>Project</SectionHeader>
            <SectionText>Design and launch a resident-focused mobile application aimed at enhancing the rental experience and increasing ancillary revenue streams.</SectionText>
            <SubSectionHeader paddingTop={40}>MY RESPONSIBILITIES</SubSectionHeader>
            <SectionText>{'\u2022'} Wireframing</SectionText>
            <SectionText>{'\u2022'} UI Design</SectionText>
            <SectionText>{'\u2022'} Prototyping</SectionText>
            <div className='LinkBtnWrapper'>
                <a className='LinkBtn' href="https://www.figma.com/proto/Wtov3LZCS8RuRxncrJv8jM/Homebody?page-id=166%3A5653&node-id=166-10730&node-type=CANVAS&viewport=-11040%2C2183%2C0.5&t=aJALKj4FaLUd5X0s-9&scaling=scale-down&content-scaling=fixed&starting-point-node-id=166%3A10730&show-proto-sidebar=1" target="_blank" rel="noopener noreferrer"> 
            Explore the Prototype
            </a>
            </div>
            <SingleColumnImg
                imgSrcOne={'Homebody/Credit-Marketplace.gif'} 
                srcOneAlt={'app interface design'}
                
            />
           
          
            <SectionHeader paddingTop={70}>Defining the objective</SectionHeader>
            <SectionText>
                <BoldSectionText>Problem: </BoldSectionText>Existing platforms lack appealing incentives, leading to limited resident interaction and underutilization. {'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Increase engagement and boost revenue by implementing an affiliate marketplace.
            </SectionText>
            <SectionText>
                <BoldSectionText>Problem: </BoldSectionText>Current resident platforms lack integration of services that are renter focused and support renters long term.{'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Enhance renting experience with rent credit reporting and comprehensive insurance options.
            </SectionText>
            <SectionText>
                <BoldSectionText>Problem: </BoldSectionText>Current renting experiences lack effective strategies to engage residents consistently and provide meaningful incentives{'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Increase engagement and enhance the renting experience through a resident reward program.
            </SectionText>
      

            <SubSectionHeader paddingTop={40}>SITE MAP</SubSectionHeader>
            <SectionText>Balancing depth of content while maintaining clear and simple navigation poses unique challenges in the context of an app. Creating a site map was pivotal for the app's development, especially as new products are continuously added and the app evolves. This was essential for organizing content logically, ensuring intuitive navigation, and supporting the app's overall growth and usability.</SectionText>
            
            <SingleColumnImg
                imgSrcOne={'Homebody/HBsitemap.png'}
                srcOneAlt={'homebody site map'}
               propClass={'fullimg'}
            />

            <SectionHeader paddingTop={70}>Ideate: Wireframes & UX</SectionHeader>
            <SubSectionHeader paddingTop={40}>ONBOARDING</SubSectionHeader>
            <SectionText>
                <BoldSectionText>Idea: </BoldSectionText>Consider implementing a chatbot for user onboarding to create a friendly and approachable experience.{'\n\r'}
                <br></br>
                <BoldSectionText>Challenge: </BoldSectionText>Limited time and resources pose constraints on implementation. {'\n\r'}
                <br></br>
                <BoldSectionText>Insight: </BoldSectionText>Given its lower return on investment compared to other priorities, the chatbot was deprioritized.{'\n\r'}
                <br></br>
                <BoldSectionText>Recommendation: </BoldSectionText>Instead, focus on designing a login screen that reflects the brand's personality through UI and tone. {'\n\r'}
            </SectionText>
            <TwoColumnImg
                imgSrcOne={'Homebody/Onboarding-wireframe.png'}
                imgSrcTwo={'Homebody/Onboarding-ux.png'}
                srcOneAlt={'homebody app onboarding wireframe'}
                srcTwoAlt={'homebody app onboarding ux'}
            />

            <SubSectionHeader paddingTop={40}>HOME</SubSectionHeader>
            <SectionText>
                <BoldSectionText>Challenge: </BoldSectionText>Stakeholders prefer the home screen not to simply recap or mirror other parts of the app.{'\n\r'}
                <br></br>
                <BoldSectionText>Insight: </BoldSectionText>The home screen needs to provide actionable value to users.{'\n\r'}
                <br></br>
                <BoldSectionText>Recommendation: </BoldSectionText>Enhance the home screen with CTAs for products not yet enrolled in, calls-to-action for affiliate partners, and a carousel featuring knowledge articles and resources.{'\n\r'}
            </SectionText>
            <TwoColumnImg
                imgSrcOne={'Homebody/Home-wireframe.png'}
                imgSrcTwo={'Homebody/Home-ux.png'}
                srcOneAlt={'homebody app home screen wireframe'}
                srcTwoAlt={'homebody app home screen ux'}
            />

            <SubSectionHeader paddingTop={40}>CREDIT</SubSectionHeader>
            <SectionText>
                <BoldSectionText>Challenge: </BoldSectionText>This product was newly integrated into the app post-acquisition.{'\n\r'}
                <br></br>
                <BoldSectionText>Insight: </BoldSectionText>Strike a balance between maintaining familiarity for existing users and integrating consistent UI elements across the app.{'\n\r'}
                <br></br>
                <BoldSectionText>Recommendation: </BoldSectionText>Create a card design that modernizes the traditional speedometer-style credit score interface to align better with other product cards.{'\n\r'}
            </SectionText>
            <TwoColumnImg
                imgSrcOne={'Homebody/Credit-wireframe.png'}
                imgSrcTwo={'Homebody/Credit-ux.png'}
                srcOneAlt={'homebody app credit screen wireframe'}
                srcTwoAlt={'homebody app credit screen ux'}
            />  

            <SubSectionHeader paddingTop={40}>INSURANCE</SubSectionHeader>
            <SectionText>
                <BoldSectionText>Challenge: </BoldSectionText>The current design fails to engage users effectively.{'\n\r'}
                <br></br>
                <BoldSectionText>Insight: </BoldSectionText>Displaying the premium prominently emphasizes costs rather than highlighting the benefits of the service.{'\n\r'}
                <br></br>
                <BoldSectionText>Recommendation: </BoldSectionText>Revamp the interface with a wallet-like design to underscore the financial aspect. Incorporate engaging illustrations to enhance visual appeal. Relocate the cost information to a separate 'Manage Services' screen to shift the focus toward user benefits.{'\n\r'}
            </SectionText>
            <TwoColumnImg
                imgSrcOne={'Homebody/insurance-wireframe.png'}
                imgSrcTwo={'Homebody/insurance-ux.png'}
                srcOneAlt={'homebody app insurance screen wireframe'}
                srcTwoAlt={'homebody app insurance screen ux'}
            />  

            <SubSectionHeader paddingTop={40}>NAVIGATION</SubSectionHeader>
            <SectionText>
                <BoldSectionText>Challenge: </BoldSectionText> Integrating 2 new products required reorganizing the navigation.{'\n\r'}
                <br></br>
                <BoldSectionText>Insight: </BoldSectionText>The 2 new products, Rewards and Offers, provide the highest potential for ancillary revenue, requiring prominent placement.{'\n\r'}
                <br></br>
                <BoldSectionText>Research: </BoldSectionText>User testing determined new icons for the navigation. Icons were tested without labels, and users were to guess what each icon represented.{'\n\r'}
                <br></br>
                <BoldSectionText>Recommendation: </BoldSectionText>Consolidate Insurance and Credit products into a tab labeled "Services." This adjustment will allocate prime navigation space to Rewards and Offers.{'\n\r'}
            </SectionText>
            <TwoColumnImg
                imgSrcOne={'Homebody/nav-wireframe.png'}
                imgSrcTwo={'Homebody/nav-ux.png'}
                srcOneAlt={'homebody app navigation screen wireframe'}
                srcTwoAlt={'homebody app navigation screen ux'}
            />  

            <SubSectionHeader paddingTop={40}>REWARDS</SubSectionHeader>
            <SectionText>
                <BoldSectionText>Challenge: </BoldSectionText>Users have multiple options for redeeming their points, such as for rent or gift cards, each with different point-to-dollar conversion ratios.{'\n\r'}
                <br></br>
                <BoldSectionText>Insight: </BoldSectionText>Users need to understand how their points and dollars translate into each redemption option.{'\n\r'}
                <br></br>
                <BoldSectionText>Research: </BoldSectionText>User testing on point redemption for rent revealed key insights: Where to display points to effectively encourage the loyalty program and its currency, Where to show dollar amounts for easy comprehension, How to use visual hierarchy to present the most relevant currency effectively.{'\n\r'}
                <br></br>
                </SectionText>
                <div className='LinkBtnWrapper'>
                <a className='LinkBtn' href="https://drive.google.com/file/d/15SKC_-l4h0-WUOo-d8_4_L119-o0QS5c/view?usp=sharing" target="_blank" rel="noopener noreferrer"> 
            View Insights & Findings
            </a>
            </div>
            <br></br>
            <SectionText>
                <BoldSectionText>Recommendation: </BoldSectionText>Consolidate Insurance and Credit products into a tab labeled "Services." This adjustment will allocate prime navigation space to Rewards and Offers.{'\n\r'}
            </SectionText>
            <TwoColumnImg
                imgSrcOne={'Homebody/rewards-wireframe.png'}
                imgSrcTwo={'Homebody/rewards-ux.png'}
                srcOneAlt={'homebody app rewards screen wireframe'}
                srcTwoAlt={'homebody app rewards screen ux'}
            />  
            <SubSectionHeader paddingTop={40}>REWARDS REDEMPTION WORKFLOW</SubSectionHeader>
            <SectionText>The redemption workflow for rewards points posed a significant challenge in managing constraints and aligning user needs with business objectives. We had to strike a balance between point-to-dollar transparency for the user, and design a system with incremental point breakage to encourage repeat transactional engagement. At the same time, it was crucial to avoid dark patterns and ensure a fair and transparent user experience.</SectionText>
            <SingleColumnImg
                imgSrcOne={'Homebody/Rewards.gif'} 
                srcOneAlt={'homebody rewards workflow'}
                
            />
           
            <div className='LinkBtnWrapper'>
                <a className='LinkBtn' href="https://www.figma.com/proto/Wtov3LZCS8RuRxncrJv8jM/Homebody?page-id=166%3A5653&node-id=166-10730&node-type=CANVAS&viewport=-11040%2C2183%2C0.5&t=aJALKj4FaLUd5X0s-9&scaling=scale-down&content-scaling=fixed&starting-point-node-id=166%3A10730&show-proto-sidebar=1" target="_blank" rel="noopener noreferrer"> 
            View Prototype
            </a>
            </div>
            <br></br>
            <SectionText>Tip: Tap 'Rewards' in the bottom nav to view the Rewards workflow</SectionText>

            <SubSectionHeader paddingTop={40}>OFFERS</SubSectionHeader>
            <SectionText>
                <BoldSectionText>Challenge: </BoldSectionText>Develop an affiliate marketplace.{'\n\r'}
                <br></br>
                <BoldSectionText>Insight: </BoldSectionText>The marketplace should seamlessly integrate with the Homebody brand while effectively showcasing advertisements from other companies.{'\n\r'}
                <br></br>
                <BoldSectionText>Research: </BoldSectionText>The wireframe included a  horizontal and vertical scrolling interface. User testing confirmed that this complicated navigation and greatly reduced visibility for ads not positioned first in the carousel.{'\n\r'}
                <br></br>
                <BoldSectionText>Recommendation: </BoldSectionText>Maintain horizontal scrolling in the hero section for an engaging, branded experience, while switching to vertical scrolling for the rest of the marketplace. This change enhances ad visibility and streamlines navigation. Infuse the Homebody brand with its distinct illustrations and colors, while ensuring that ads clearly display their own company logos for brand distinction.{'\n\r'}
            </SectionText>
            <TwoColumnImg
                imgSrcOne={'Homebody/offers-wireframe.png'}
                imgSrcTwo={'Homebody/offers-ux.png'}
                srcOneAlt={'homebody app offers screen wireframe'}
                srcTwoAlt={'homebody app offers screen ux'}
            /> 

           
            <SectionHeader paddingTop={70}>UI Design</SectionHeader>
            <SectionText>I designed the UI using an existing digital marketing brand book, which posed a unique challenge. Transforming static design guidelines into an interactive app required not only adapting visual elements but also reimagining how these elements function dynamically.</SectionText>
            <ThreeColumnImg
                imgSrcOne={'Homebody/UI-A.png'}
                imgSrcTwo={'Homebody/UI-B.png'}
                imgSrcThree={'Homebody/UI-C.png'}
                srcOneAlt={'Homebody UI option A'}
                srcTwoAlt={'Homebody UI option B'}
                srcThreeAlt={'Homebody UI option C'}
            />
            <SubSectionHeader paddingTop={40}>REFINING THE UI</SubSectionHeader>
            <SectionText>These iterations were foundational in exploring the personality of Homebody through the UI, in a way that felt seamless across platforms. These primary components were key:{'\n\r'}
            <br></br>
                <BoldSectionText>Rounded elements: </BoldSectionText>Rounded elements are welcoming and helped make interactive components more intuitive to tap.{'\n\r'}
                <br></br>
                <BoldSectionText>Illustration: </BoldSectionText>Connected the homebody personality and brand to the user in a friendly and approachable way.{'\n\r'}
                <br></br>
                <BoldSectionText>Colors: </BoldSectionText>Emerald and mint were a challenge to work with. To lighten the heaviness of emerald, and increase the accessibility of mint, colors are used minimally, and are highly intentional.{'\n\r'}
            </SectionText>

            <TwoColumnImg
                imgSrcOne={'Homebody/UI-library-A.png'}
                imgSrcTwo={'Homebody/UI-library-B.png'}
                srcOneAlt={'homebody app design library elements'}
                srcTwoAlt={'homebody app design library elements'}
            /> 

            <SectionHeader paddingTop={70}>Dev prep + handoff</SectionHeader>
            <SectionText>With the engineering team located in India and Russia, it was imperative to have consistent communication and stay aligned to make timely progress. I did this by:</SectionText>
            <SectionText>{'\u2022'} Sending daily recaps of the days work via a slack message</SectionText>
            <SectionText>{'\u2022'} Direct communication via daily standups</SectionText>
            <SectionText>{'\u2022'} Documenting a changelog of revisions in the Figma file</SectionText>
            <br></br>
            <SectionText>Preparing the Figma files for handoff involved detailed documentation of error states, zero states, and user actions to ensure clarity for contractors, who were unfamiliar with the project’s life thus far. I tested thoroughly and documented bugs in various scenarios which was crucial for a successful launch.</SectionText>

                
          


            <SectionHeader paddingTop={70}>Challenges & Takeaways</SectionHeader>
            <SectionText>Limited direction pushed me to iterate rapidly. Despite minimal guidance, I effectively managed strong feedback and a tight deadline. </SectionText>
            <SectionText>Dynamic content required flexibility. I needed to adapt with app content and evolving requests.</SectionText>
            <SectionText>As the primary liaison, consolidating feedback from engineers, product leaders, and stakeholders drove me to enhance documentation and centralize communication.</SectionText>

           
            <SubSectionHeader paddingTop={50}>NEXT STEPS</SubSectionHeader>
            <SectionText>I’m currently designing a move-in onboarding workflow for residents. This will allow them to seamlessly sign up for utilities, insurance, internet, forward their address, and more—all in one place. Another feature to enhance the resident experience, and boost revenue with affiliate marketing. Here's a sneak peak! </SectionText>
            <SingleColumnImg
                imgSrcOne={'Homebody/Move.gif'} 
                srcOneAlt={'homebody move workflow'}
                
            />
           <Footer></Footer>
        </div>
    )
}
