import React from 'react'
import { LinkContainer } from 'react-router-bootstrap';
import './Footer.css';

export default function Footer() {
    return (
        <div className='FooterBckgrd'>
            <h4 className='FooterTitle'>MAGGIE CALL</h4>
            <div className='FooterLinksWrapper'>
                <div>
                    <h4 className='FooterTitle'>WORK</h4>
                    <ul>
                        <li className='FooterLink'>
                            <LinkContainer to="/UxUi">
                                <a href="/UxUi" className='FooterLinkText'>ux/ui</a>
                            </LinkContainer>
                        </li>
                        <li className='FooterLink'>
                            <LinkContainer to="/branding">
                                <a href="/branding" className='FooterLinkText'>branding</a>
                            </LinkContainer>
                        </li>
                        <li className='FooterLink'>
                            <LinkContainer to="/motion">
                                <a href="/motion" className='FooterLinkText'>motion</a>
                            </LinkContainer>
                        </li>
                        <li className='FooterLink'>
                            <LinkContainer to="/infographics">
                                <a href="/infographics" className='FooterLinkText'>infographics</a>
                            </LinkContainer>
                        </li>
                        <li className='FooterLink'>
                            <LinkContainer to="/invitations">
                                <a href="/invitations" className='FooterLinkText'>invitations</a>
                            </LinkContainer>
                        </li>
                    </ul>

                </div>
                <div className='FooterTitle FooterAboutWrapper'>
                    <LinkContainer to="/about">
                        <a href="/about" className='FooterTitle LinkTitle'>ABOUT</a>
                    </LinkContainer>
                </div>
            </div>

        </div>
    )
}