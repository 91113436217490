import React from "react";
import { Route, Switch } from "react-router-dom";
import Home  from "./containers/Home";
import About from "./containers/About";
import NotFound  from "./containers/NotFound";
import Branding from "./containers/Branding";
import Infographics from "./containers/Infographics";
import Motion from "./containers/Motion";
import Invitations from "./containers/Invitations";
import ViewImage from "./containers/ViewImage";
import UxUi from './containers/UxUi';
import CookedUxUi from './containers/CookedUxUi';
import CookingUsability from './containers/CookingUsability';
import HonedUxUi from './containers/HonedUxUi';
import Usability from './containers/Usability';
import ClassManagement from './containers/ClassManagement';
import Migrations from './containers/Migrations';
import HomeBody from './containers/HomeBody';
import Iconography from './containers/Iconography';
import ClimbingPartnerFinder from './containers/ClimbingPartnerFinder';
import CreativePrompts from './containers/CreativePrompts';

import AppliedRoute from "./components/AppliedRoutes";




const navRoutes = ({ childProps }) =>
    <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <AppliedRoute path="/branding" exact component={Branding} props={childProps} />
        <AppliedRoute path="/infographics" exact component={Infographics} props={childProps} />
        <AppliedRoute path="/motion" exact component={Motion} props={childProps} />
        <AppliedRoute path="/invitations" exact component={Invitations} props={childProps} />
        <AppliedRoute path="/UxUi" exact component={UxUi} props={childProps}/>
        <AppliedRoute path="/viewImage" exact component={ViewImage} />
        <AppliedRoute path="/cookedUxUi" exact component={CookedUxUi} props={childProps} />
        <AppliedRoute path="/honedUxUi" exact component={HonedUxUi} props={childProps} />
        <AppliedRoute path="/classManagement" exact component={ClassManagement} props={childProps} />  
        <AppliedRoute path="/migrations" exact component={Migrations} props={childProps} /> 
        <AppliedRoute path="/homeBody" exact component={HomeBody} props={childProps} />  
        <AppliedRoute path="/iconography" exact component={Iconography} props={childProps} />          
        <AppliedRoute path="/CreativePrompts" exact component={CreativePrompts} props={childProps} />
        <AppliedRoute path="/climbingPartnerFinder" exact component={ClimbingPartnerFinder} props={childProps} />
        <Route path="/cookingUsability" exact component={CookingUsability} />
        <Route path="/usability" exact component={Usability} />
        <Route component={NotFound} />
    </Switch>

    export default navRoutes;