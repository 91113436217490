import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import SectionText from '../components/text/SectionText';
import BoldSectionText from '../components/text/BoldSectionText';
import SubSectionHeader from '../components/text/SubSectionHeader';
import ThreeColumnImg from '../components/ThreeColumnImg';
import TwoColumnImg from '../components/TwoColumnImg';
import SingleColumnImg from '../components/SingleColumnImg';
import { FiArrowLeft } from 'react-icons/fi';
import Footer from '../components/Footer';

export default function CookedUxUi(props) {
    const backDiv = {
        paddingTop: 50,
    }
    return (
            <div className='container'>
                <div style={backDiv} className="btnDiv">
                    <button className="simpleBtn" onClick={() => { props.history.goBack() }}><FiArrowLeft /> back to UX/UI </button>
                </div>
                <SectionHeader>Project</SectionHeader>
                <SectionText>Create an app for food bloggers using the API from their Wordpress blog.</SectionText>

                <SubSectionHeader paddingTop={40}>BACKGROUND</SubSectionHeader>
                <SectionText>Food bloggers feel maxxed out. They are constantly blogging and repurposing content for social media channels and email marketing.</SectionText>

                <SubSectionHeader paddingTop={40}>GOAL</SubSectionHeader>
                <SectionText>Create a whitelabel solution for bloggers to expand their audience, reach, and monetize their content in a whole new way, with a dedicated mobile app. The dedicated mobile app must require little to no work for the blogger, and improve on the user experience utilizing native features apps offer.</SectionText>

                <SubSectionHeader paddingTop={40}>MY RESPONSIBILITIES</SubSectionHeader>
                <SectionText>{'\u2022'} User Research</SectionText>
                <SectionText>{'\u2022'} Wireframing</SectionText>
                <SectionText>{'\u2022'} Prototyping</SectionText>
                <SectionText>{'\u2022'} Usability Testing</SectionText>
                <SectionText>{'\u2022'} UI Design</SectionText>

                <ThreeColumnImg
                    imgSrcOne={'Cooked/Cooked_Design-Home.jpg'}
                    imgSrcTwo={'Cooked/Cooked_Feature-B.jpg'}
                    imgSrcThree={'Cooked/Cooked_Feature-C.jpg'}
                    srcOneAlt={'Home screen of cooked app'}
                    srcTwoAlt={'Shopping list of cooked app'}
                    srcThreeAlt={'Recipe page of cooked app'}
                />

                <SectionHeader paddingTop={25}>User Research</SectionHeader>
                <SectionText>User research uncovered these 3 main problems:</SectionText>
                <SectionText>
                    <BoldSectionText>Pain Point: </BoldSectionText>Food bloggers main income comes from advertisements on their blog, but they frequently get complaints from users about having too many ads.{'\n\r'}
                    <BoldSectionText>Goal: </BoldSectionText>Allow in-app ads on the dedicated app and offer a paid version with no ads.
                </SectionText>
                <SectionText>
                    <BoldSectionText>Pain Point: </BoldSectionText>Food bloggers spend a lot of time writing blog posts to rank well for SEO, but get complaints that their posts are too long.{'\n\r'}
                    <BoldSectionText>Goal: </BoldSectionText>Expand/collapse blog post copy so users can scroll past it quickly.
                </SectionText>
                <SectionText>
                    <BoldSectionText>Pain Point: </BoldSectionText>The idea of a dedicated mobile app is exciting, but it feels foreign and unknown. The cost and time feel too overwhelming.
                    {'\n\r'}
                    <BoldSectionText>Goal: </BoldSectionText>Use Wordpress API so the app gets data from the same servers as the food bloggers website. This means any changes the blogger makes to their website will be automatically updated and reflected in the app.
                </SectionText>


                <SubSectionHeader paddingTop={40}>IDEATION {'&'} GOALS</SubSectionHeader>
                <SectionText>{'\u2022'} We want every Wordpress blog post to automatically update in the dedicated mobile app, creating no extra work for the food blogger</SectionText>
                <SectionText>{'\u2022'} We want the food blogger to be able to send push notifications to their audience and increase reach and brand awareness</SectionText>
                <SectionText>{'\u2022'} We want to increase usability and functionality for the user. Create features like built-in timers, creating weekly meal plans, and more robust search functionality.</SectionText>
                <SectionText>{'\u2022'} We want users to be able to interact with content, being able to ‘like’ recipes, create collections, add notes to a recipe, and create/share grocery lists</SectionText>
                <SectionHeader paddingTop={70}>Sketches</SectionHeader>
                <SectionText>With goals in mind, I began sketching out layout ideas and features.</SectionText>
                <ThreeColumnImg
                    imgSrcOne={'Cooked/Cooked_Sketch-A.jpg'}
                    imgSrcTwo={'Cooked/Cooked_Sketch-B.jpg'}
                    imgSrcThree={'Cooked/Cooked_Sketch-C.jpg'}
                    srcOneAlt={'Sketch of cooked shopping list.'}
                    srcTwoAlt={'Sketch of cooked recipe page 1.'}
                    srcThreeAlt={'Sketch of cooked recipe page 2'}
                />
                <ThreeColumnImg
                    imgSrcOne={'Cooked/Cooked_Sketch-D.jpg'}
                    imgSrcTwo={'Cooked/Cooked_Sketch-E.jpg'}
                    imgSrcThree={'Cooked/Cooked_Sketch-F.jpg'}
                    srcOneAlt={'Sketch of cooked recipe box.'}
                    srcTwoAlt={'Sketch of cooked explore page.'}
                    srcThreeAlt={'Sketch of cooked recipe box version 2'}
                />
                <SectionHeader paddingTop={50}>Wireframe</SectionHeader>
                <SectionText>After a few revisions to the sketches, I began wireframing. These would be the visuals we would use for usability testing.</SectionText>
                <ThreeColumnImg
                    imgSrcOne={'Cooked/Cooked_Wireframe-A.jpg'}
                    imgSrcTwo={'Cooked/Cooked_Wireframe-B.jpg'}
                    imgSrcThree={'Cooked/Cooked_Wireframe-C.jpg'}
                    srcOneAlt={'img one'}
                    srcTwoAlt={'img two'}
                    srcThreeAlt={'img three'}
                />
                <ThreeColumnImg
                    imgSrcOne={'Cooked/Cooked_Wireframe-D.jpg'}
                    imgSrcTwo={'Cooked/Cooked_Wireframe-E.jpg'}
                    imgSrcThree={'Cooked/Cooked_Wireframe-F.jpg'}
                    srcOneAlt={'img one'}
                    srcTwoAlt={'img two'}
                    srcThreeAlt={'img three'}
                />
                <SectionHeader paddingTop={50}>Usability Testing</SectionHeader>
                <SectionText>User testing found these 4 main problems:</SectionText>
                <SectionText>
                    <BoldSectionText>Observation: </BoldSectionText>Users didn’t know how to use the step-by-step functionality {'\n\r'}
                    <BoldSectionText>Insight: </BoldSectionText>Omit this feature, as it appeared to be ignored, unused and confusing
                </SectionText>
                <SectionText>
                    <BoldSectionText>Observation: </BoldSectionText> Users didn’t recognize share/move icons {'\n\r'}
                    <BoldSectionText>Insight: </BoldSectionText> Align with the industry’s standardized icons (and device-specific icons) for these tasks, consider labeling them
                </SectionText>
                <SectionText>
                    <BoldSectionText>Observation: </BoldSectionText> Users had trouble clearing items on a grocery list{'\n\r'}
                    <BoldSectionText>Insight: </BoldSectionText> Relabel button to be more clear
                </SectionText>
                <SectionText>
                    <BoldSectionText>Observation: </BoldSectionText> Users wanted to be able to reorder items in their grocery list. They didn’t want multiple lists per recipe.{'\n\r'}
                    <BoldSectionText>Insight: </BoldSectionText> Allow users to move items. Omit multiple lists.
                </SectionText>
                <SectionText>After several refinements from our findings during the usability tests, I moved onto design.</SectionText>
                <SectionHeader paddingTop={70}>Design</SectionHeader>
                <SectionText>Our first food blogger was 5 Boys Baker. In order to keep a strong visual brand presence, I mimicked the 5 Boys Baker brand from their website, using the same sans serif typography and peeks of yellow throughout.</SectionText>
                <SubSectionHeader paddingTop={70}>HOME FEED {'&'} MENU</SubSectionHeader>
                <SectionText>The home feed features an infinite scroll of recipes. The menu is a sliding drawer.</SectionText>
                <TwoColumnImg
                    imgSrcOne={'Cooked/Cooked_Design-Home.jpg'}
                    imgSrcTwo={'Cooked/Cooked_Design-Menu.jpg'}
                    srcOneAlt={'Cooked home screen display.'}
                    srcTwoAlt={'Cooked menu screen display'}
                />
                <SubSectionHeader paddingTop={70}>RECIPE</SubSectionHeader>
                <SectionText>Users can dynamically adjust the ‘scale’ of the recipe, add ingredients to the grocery list, use the built-in timers, and write notes/adjustments.</SectionText>
                <SingleColumnImg
                    imgSrcOne={'Cooked/Cooked_Design-Recipe.jpg'}
                    srcOneAlt={'Cooked Recipe design.'}
                />
                <SubSectionHeader paddingTop={70}>GROCERY LIST</SubSectionHeader>
                <SectionText>Users can add custom grocery items, check/uncheck items, and share the list.</SectionText>
                <TwoColumnImg
                    imgSrcOne={'Cooked/Cooked_Design-GroceryList-A.jpg'}
                    imgSrcTwo={'Cooked/Cooked_Design-GroceryList-B.jpg'}
                    srcOneAlt={'Cooked grocery list design'}
                    srcTwoAlt={'Cooked popup design'}
                />
                <SubSectionHeader paddingTop={70}>SAVED RECIPES</SubSectionHeader>
                <SectionText>Saved recipes are organized by the category</SectionText>
                <SingleColumnImg
                    imgSrcOne={'Cooked/Cooked_Design-SavedRecipes.jpg'}
                    srcOneAlt={'Cooked saved recipes design.'}
                />
                <SubSectionHeader paddingTop={70}>COLLECTIONS</SubSectionHeader>
                <SectionText>Users can create collections of their favorite recipes.</SectionText>
                <TwoColumnImg
                    imgSrcOne={'Cooked/Cooked_Design-Collections-A.jpg'}
                    imgSrcTwo={'Cooked/Cooked_Design-Collections-B.jpg'}
                    srcOneAlt={'Cooked Collection explore design.'}
                    srcTwoAlt={'Cooked Collection popup design.'}
                />
                <SectionText paddingTop={70}>Users can add to their collection through their saved recipes, or move recipes from one collection to another.</SectionText>
                <TwoColumnImg
                    imgSrcOne={'Cooked/Cooked_Design-Collections-C.jpg'}
                    imgSrcTwo={'Cooked/Cooked_Design-Collections-D.jpg'}
                    srcOneAlt={'Cooked add to collection design.'}
                    srcTwoAlt={'Cooked move to collection design.'}
                />
                <SectionHeader paddingTop={70}>Key takeaway</SectionHeader>

                <SectionText paddingTop={40}><BoldSectionText>Checking Bias in Design: </BoldSectionText>I had designed the wireframe with Android-inspired share and move icons. The users we conducted user testing with were iOS users and were confused by these symbols. I need to better consider how various design systems might play into the app design and affect user testing. This also proves a case for labeling icons to eliminate confusion. </SectionText>
                <SectionText paddingTop={20}><BoldSectionText>UX Writing: </BoldSectionText>Language and word choice became really important in communicating to our users. For example, in a 'Notes' textbox, we combined a prompt with an example. So instead of a prompt like "add a note...", it is now "Try adding a note like 'Bake 5 minutes longer.'" It sounds inviting and the user gets an idea of how to use the notes feature.</SectionText>
                <SectionText>In other cases, this approach didn't work well. We had "Recipe Box" in the menu to house saved recipes. We thought it was a clever call to food bloggers. But during testing, users were unclear on what it was. We then changed it to Saved Recipes. </SectionText>
                <SectionText paddingTop={20}><BoldSectionText>Content: </BoldSectionText>Considering the content and imagery I was working with, this insighted an unnecessary (unhealthy?) amount of baking. A positive (or negative?) byproduct of working on this project.</SectionText>

                <SubSectionHeader paddingTop={40}>NEXT STEPS</SubSectionHeader>
                <SectionText>{'\u2022'} Revise light gray in color palette to a more accessible color concerning color contrast</SectionText>
                <SectionText>{'\u2022'} Continue competitive research to discover possible features to test and implement</SectionText>

                <SectionHeader paddingTop={70}>Results</SectionHeader>
                <SectionText paddingTop={20}><BoldSectionText>Market Research: </BoldSectionText>While user research was promising and we had positive responses in testing, the general market was not interested. In trying to market this app to food bloggers, response was almost non existent. This could be due to a variety of factors like our price point for the app, poor marketing strategy/communication, or the market isn't ready.</SectionText>
                <SectionText paddingTop={20}><BoldSectionText>Development: </BoldSectionText>The software engineer was able to use Wordpress API and plugins connected to the blog to successfully display blog content in the app. The timers were very tricky on the development side of things, with a fair share of issues to debug. </SectionText>
                <SectionText paddingTop={20}><BoldSectionText>Features: </BoldSectionText>We were really excited about how robust the search functionality turned out. While a wordpress blog search functionality basically just matches the words a user searches, with a blog post, this apps search functionality allows users to search cook time, and specific ingredients. This is much more useful to the user.</SectionText>

                <Footer></Footer>

            </div>
    )
}
