import React from 'react'
import './SectionText.css';

export default function SectionText({children, paddingTop = 0, paddingBottom = 0, textAlign = 'left', justifyContent = 'start'}) {

    const divStyle={
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
        justifyContent: justifyContent,
    }

    const pStyle={
        textAlign: textAlign,
    }

    return (
        <div style={divStyle} className='sectionTextContainer'>
            <p style={pStyle} className='sectionText'>{children}</p>
        </div>
    )
}
