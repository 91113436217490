import React, { Component } from 'react';
import './App.css';
import Routes from "./Routes";
//import { Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
//import { LinkContainer } from 'react-router-bootstrap';
import { Provider } from 'react-redux';
import store from './store';
import Navigation from './containers/Navigation';
let cats = [
  {
    key: 1,
    img: "cat.png",
    alt: "cat"
  },
  {
    key: 2,
    img: "cat2.png",
    alt: "cat"
  },
  {
    key: 3,
    img: "cat3.png",
    alt: "cat"
  },
  {
    key: 4,
    img: "cat4.png",
    alt: "another cat"
  },
  {
    key: 5,
    img: "cat5.png",
    alt: "antohhhasdf"
  }
]

const brandingThumb = [
  {
    key: 1,
    img: "Branding_KDVS",
    alt: "KDVS thumbnail"
  },
  {
    key: 2,
    img: "Branding_BetterU",
    alt: "BetterU thumbnail"
  },
  {
    key:3,
    img: "Branding_HelpCloud",
    alt: "HelpCloud thumbnail"
  },
  {
    key: 4,
    img: "Branding_Jane",
    alt: "Jane thumbnail"
  },
  {
    key: 5,
    img: "Branding_5BB",
    alt: "5BB thumbnail"
  },
  {
    key: 6,
    img: "Branding_nomo",
    alt: "nomo thumbnail"
  },
  {
    key: 7,
    img: "Branding_SunderEnergy",
    alt: "SunderEnergy thumbnail"
  }
]

const infographicThumb = [
  {
    key: 1,
    img: "CriminalDefense",
    alt: "Criminal Defense Infographic Thumbnail"
  },
  {
    key: 2,
    img: "Fears",
    alt: "Fears Infographic Thumbnail"
  },
  {
    key: 3,
    img: "MamaSezz",
    alt: "Mama Sezz Infographic Thumbnail"
  },
  {
    key: 4,
    img: "ChannelSlam",
    alt: "Channel Slam Infographic Thumbnail"
  },
  {
    key: 5,
    img: "Developers",
    alt: "Developers Infographic Thumbnail"
  },
  {
    key: 6,
    img: "SocialMediaCourt",
    alt: "Social Media Court Infographic Thumbnail"
  },
  {
    key: 7,
    img: "Javascript",
    alt: "Javascript Infographic Thumbnail"
  },
  {
    key: 8,
    img: "BizTips",
    alt: "BizTips Infographic Thumbnail"
  }

]

const motion = [
  {
    key: 1,
    img: "motion_bad_cs",
    alt: "bad cs gif"
  },
  {
    key: 2,
    img: "motion_Brand_Evangelists",
    alt: "Brand Evangelists gif"
  },
  {
    key: 3,
    img: "motion_CRM",
    alt: "CRM gif"
  },
  {
    key: 4,
    img: "motion_HelpDesk",
    alt: "Help Desk gif"
  },
  {
    key: 5,
    img: "motion_Inventory",
    alt: "Inventory gif"
  },
  {
    key: 6,
    img: "motion_Omnichannel",
    alt: "Omnichannel gif"
  },
  {
    key: 7,
    img: "motion_Portals",
    alt: "Portals gif"
  },
  {
    key: 8,
    img: "motion_Real_Estate",
    alt: "Real Estate gif"
  }
]

const invites = [
  {
    key: 1,
    img: "Invitations_JaneWedding-A",
    alt: "Jane Wedding Invite Front"
  },
  {
    key: 2,
    img: "Invitations_JaneWedding-B",
    alt: "Jane Wedding Invite Back"
  },
  {
    key: 3,
    img: "Invitations_Call2017-A",
    alt: "Call Christmas Card Front"
  },
  {
    key: 4,
    img: "Invitations_Call2017-B",
    alt: "Call Christmas Card Back"
  },
  {
    key: 5,
    img: "Invitations_Recital-A",
    alt: "Piano Recital Front"
  },
  {
    key: 6,
    img: "Invitations_Recital-B",
    alt: "Piano Recital Back"
  },
  {
    key: 7,
    img: "Invitations_Call2018-A",
    alt: "Call Christmas Card Front"
  },
  {
    key: 8,
    img: "Invitations_Call2018-B",
    alt: "Call Christmas Card Back"
  },
  {
    key: 9,
    img: "Invitations_ChocolateParty-A",
    alt: "Chocolate Party Front"
  },
  {
    key: 10,
    img: "Invitations_ChocolateParty-B",
    alt: "Chocolate Party Back"
  },
  {
    key: 11,
    img: "Invitations_Call2016-A",
    alt: "Call Christmas Card Front"
  },
  {
    key: 12,
    img: "Invitations_Call2016-B",
    alt: "Call Christmas Card Back"
  },
  {
    key: 13,
    img: "Invitations_WhitneyShower-A",
    alt: "Whitney Shower Front"
  },
  {
    key: 14,
    img: "Invitations_WhitneyShower-B",
    alt: "Whitney Shower Back"
  }
]

class App extends Component {

  constructor(props) {
    super(props);

    this.state= {
      currentImg: "test.png",
      background: true
    }
  }

  viewImageChange = img => {
    this.setState({ currentImg: img});
    console.log(img);
  }

  render(){
    const childProps = {
      media: true,
      test: "test.png",
      cat: cats,
      infographics: infographicThumb,
      branding: brandingThumb,
      motion: motion,
      invites: invites,
      currentImg: this.currentImg,
      viewImageChange: this.viewImageChange
    };
    return (
      <Provider store={store}>
      <div className="App container">
        <Navigation></Navigation>
        <Routes childProps={childProps} />
      </div>
      </Provider>
    );
  }
}

export default withRouter(App);
