import React from 'react'
import './ThreeColumnImg.css';

export default function ThreeColumnImg({
    imgSrcOne, 
    imgSrcTwo, 
    imgSrcThree,
    srcOneAlt,
    srcTwoAlt,
    srcThreeAlt,
}) {
    return (
        <div className='threeColImgContainer'>
            <div className='threeColImg'>
                <img src={require('../media/' + imgSrcOne)} alt={srcOneAlt} />
            </div>
            <div className='threeColImg'>
                <img src={require("../media/" + imgSrcTwo)} alt={srcTwoAlt}/>
            </div>
            <div className='threeColImg'>
                <img src={require("../media/" + imgSrcThree)} alt={srcThreeAlt}/>
            </div>
        </div>
    )
}
