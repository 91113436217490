import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import SectionText from '../components/text/SectionText';
import BoldSectionText from '../components/text/BoldSectionText';
import SubSectionHeader from '../components/text/SubSectionHeader';
import ThreeColumnImg from '../components/ThreeColumnImg';
import TwoColumnImg from '../components/TwoColumnImg';
import SingleColumnImg from '../components/SingleColumnImg';
import Footer from '../components/Footer';
import { FiArrowLeft } from 'react-icons/fi';
import './CreativePrompts.css';

export default function CreativePrompts(props) {
    const backDiv = {
        paddingTop: 50,
    }
    return (
        <div className='container'>
            <div style={backDiv} className="btnDiv">
                <button className="simpleBtn" onClick={() => {props.history.goBack()}}><FiArrowLeft /> back to UX/UI </button>
            </div>
            <SectionHeader>Project</SectionHeader>
            <SectionText>Create an app for kids that encourages creative and explorative play, off-screen.</SectionText>
            <SubSectionHeader paddingTop={40}>BACKGROUND</SubSectionHeader>
            <SectionText>With school and entertainment on devices, parents feel that kids need time away from screens. Parents need resources to encourage exploration and creative play for their kids.</SectionText>
            <SubSectionHeader paddingTop={40}>GOAL</SubSectionHeader>
            <SectionText>Create an app that promotes off-screen play. Encourage creative, open play in an explorative way.</SectionText>
            <SubSectionHeader paddingTop={40}>MY RESPONSIBILITIES</SubSectionHeader>
            <SectionText>{'\u2022'} User Research</SectionText>
            <SectionText>{'\u2022'} Wireframing</SectionText>
            <SectionText>{'\u2022'} Prototyping</SectionText>
            <SectionText>{'\u2022'} Usability Testing</SectionText>
            <SectionText>{'\u2022'} UI Design</SectionText>
            <SingleColumnImg
                imgSrcOne={'CreativePrompts/MobileGIF.gif'} 
                srcOneAlt={'app interface design'}
                
            />
          
            <SectionHeader paddingTop={70}>User Research</SectionHeader>
            <SectionText>With school and entertainment (movies, tv shows, video games) on devices, parents feel their kids need a break from screens. Because of constant screens, kids miss out on imaginative, explorative play.</SectionText>
            <SectionText>User research uncovered these 3 main problems:</SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>When parents take away devices from kids, they often hear “I’m bored.”{'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create an app that encourages child-led creative, imaginative, and explorative play</SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>Parents feel guilty and often “give in” when their child is acting out by giving them a screen to keep them quiet and entertained (while waiting at a doctors office, driving in the car, waiting at a restaurant etc). {'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create an app that doesn’t require material resources and can be used on-the-go
            </SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>Parents want to encourage children to play on their own and as a group (with siblings, or friends){'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create an app for a wide range of ages, and no limits on amount of ‘players’
                </SectionText>

            <SubSectionHeader paddingTop={40}>PERSONAS</SubSectionHeader>
            <TwoColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_Sara.jpg'}
                imgSrcTwo={'CreativePrompts/CreativePrompts_Eva.jpg'}
                srcOneAlt={'Sara Persona'}
                srcTwoAlt={'Eva Persona'}
            />

            <SubSectionHeader paddingTop={40}>USER JOURNEY MAPS</SubSectionHeader>
            <SectionText>I then created user journey maps for Sara and Eva. It was important to understand the context in which this app would be used, and the problems they faced.</SectionText>
            <SectionText>Sara’s children rely on her for entertainment. She finds it difficult to find a moment to prepare dinner when her children aren’t constantly needing her.</SectionText>

            <SectionText>Eva’s children get restless while waiting at the doctors office. She finds it difficult to entertain her children on-the-go, where bringing toys/games aren’t appropriate or bringn too much hassle/distraction. </SectionText>
            <SingleColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_UserJourneyMap.jpg'}
                srcOneAlt={'User Journey Map'}
                propClass={'fullimg'}
            />

            <SectionHeader paddingTop={70}>Ideate</SectionHeader>
            <SubSectionHeader paddingTop={40}>COMPETITIVE AUDIT</SubSectionHeader>
            <SectionText>There are several creative prompt websites (Designercize, Sharpen, Brief Biz, etc) that generate ideas for design projects geared towards entrepreneurs, professionals, and students. </SectionText>
            <SectionText>Then, there are apps that generate lifestyle prompts (like Bored, and I Dare You). Bored generates general ideas (“Write in your journal”) while I Dare You generates ‘Dares’ for adults ("Show your google search history."). </SectionText>
            <SectionText><BoldSectionText>Opportunity Gap:</BoldSectionText></SectionText>
            <SectionText>{'\u2022'} Creative prompts geared towards kids</SectionText>
            <SectionText>{'\u2022'} Creative prompts that encourage creative and imaginative play (less realistic prompts, more funny and imaginative)</SectionText>
            <SectionText>{'\u2022'} Creative prompts that explore the arts (drawing, acting, creative storytelling)</SectionText>
            <SectionText>{'\u2022'} Promote growth in verbal communication and fine/gross motor skills, creative expression, hand-eye coordination </SectionText>
            
            <SubSectionHeader paddingTop={40}>IDEATION</SubSectionHeader>
            <SectionText>Through Crazy Eights and competitive audit analysis, I came to 4 crucial needs of the app.</SectionText>
            <SingleColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_CrazyEights.jpg'} 
                srcOneAlt={'crazy eights sketches'}
                propClass={'fullimg'}
            />
            <SectionText>
                <BoldSectionText>Categorize creative prompts (Drawing, Acting, Storytelling)</BoldSectionText>{'\n\r'}
                This will help organize the app and allow users to choose an activity that they are drawn to.</SectionText>
            <SectionText>   
                <BoldSectionText>Be visual </BoldSectionText>{'\n\r'}
                The use of emoji’s will increase accessibility to non-readers by visualizing the phrase for children who can’t read.</SectionText>
            <SectionText>
                <BoldSectionText>Accessible</BoldSectionText>{'\n\r'}
                Increase accessibility by including large target areas for buttons and “read to me” button that will read the phrase for children who are non-readers. {'\n\r'}</SectionText>
           
            <SectionText>
                <BoldSectionText>Simple</BoldSectionText>{'\n\r'}
                It was important to give the user as little choices/customization/interaction with the app, as that would increase time on screen. The app needed to be simple so users wouldn’t be constantly finessing with choices, and interactions on screen.{'\n\r'}</SectionText>



            <SectionHeader paddingTop={70}>Wireframe</SectionHeader>
            <SectionText>I created 5 paper wireframes of each main screen. I then starred elements on each wireframe that I liked, and created a sixth version of my starred elements in one screen design.
</SectionText>
            <SingleColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_PaperWireframe.jpg'}
                srcOneAlt={'paper wireframe sketches'}
                propClass={'fullimg'}
            />
    
            <SectionText> I began to digitize my paper wireframes. </SectionText>
            <SingleColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_Wireframe-A.jpg'}
                srcOneAlt={'Wireframe of screen with labeled design choices'}
                propClass={'fullimg'}
           
            />
            <ThreeColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_Wireframe-B.jpg'}
                imgSrcTwo={'CreativePrompts/CreativePrompts_Wireframe-C.jpg'}
                imgSrcThree={'CreativePrompts/CreativePrompts_Wireframe-D.jpg'}
                srcOneAlt={'Act screen wireframe'}
                srcTwoAlt={'Story screen wireframe'}
                srcThreeAlt={'Saved screen wireframe'}
            />


            <SectionHeader paddingTop={70}>Usability Study: Round 1 Findings</SectionHeader>
            <SectionText>I conducted 5 usability tests. Each test was held in-person and moderated. Users were asked to perform a set of tasks with the low-fidelity prototype. Below are themes and insights from these tests.</SectionText>
<           SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Participants were confused on how to see a new prompt{'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Users need a more clear and direct visual queue to see a new prompt (to swipe)
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Participants didn’t know how to save a prompt for later {'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Users need better labeling to the favorites menu and heart icon to know they are connected.
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Participants were confused about the heart icon {'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>It is not clear what the heart icon does.
            </SectionText>
           
        
            <div className='LinkBtnWrapper'>
            <a className='LinkBtn' href="https://www.figma.com/proto/L3LXhiYvDiWrnKYYdzIZIM/CoffeeHouse?node-id=208%3A105&scaling=scale-down&page-id=205%3A2&starting-point-node-id=214%3A739&show-proto-sidebar=1" target="_blank" rel="noopener noreferrer"> 
            View Low-Fi Mobile Prototype
            </a>
            </div>
                
            <SubSectionHeader paddingTop={70}>REVISED DESIGNS</SubSectionHeader>
            <SectionText>
            <BoldSectionText>Insight: </BoldSectionText> Users need a more clear and direct visual queue to see a new prompt (to swipe).</SectionText>
            <TwoColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_UsabilityBefore-A.jpg'}
                imgSrcTwo={'CreativePrompts/CreativePrompts_UsabilityAfter-A.jpg'}
                srcOneAlt={'before usabilty study screenshot'}
                srcTwoAlt={'after usability study screenshot'}
            />

            <SectionText paddingTop={100}>
            <BoldSectionText>Insight: </BoldSectionText> Users need better labeling to the favorites menu and heart icon to know they are connected. It is not clear what the heart icon does. </SectionText>

            <TwoColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_UsabilityBefore-B.jpg'}
                imgSrcTwo={'CreativePrompts/CreativePrompts_UsabilityAfter-B.jpg'}
                srcOneAlt={'before usabilty study screenshot'}
                srcTwoAlt={'after usability study screenshot'}
            />

            
            <SectionHeader paddingTop={70}>Design</SectionHeader>  
            <SectionText>After implementing usability test insights, it was time for design. I wanted a colorful look that felt active and playful. I chose a sans serif typeface that is wide and chunky, for increased readability. It feels modern, yet approachable and friendly. </SectionText>
            <div className='LinkBtnWrapper'>
                <a className='LinkBtn' href="https://www.figma.com/proto/L3LXhiYvDiWrnKYYdzIZIM/CoffeeHouse?node-id=266%3A3728&scaling=scale-down&page-id=205%3A2&starting-point-node-id=266%3A3728&show-proto-sidebar=1" target="_blank" rel="noopener noreferrer"> 
            View High-Fi Mobile Prototype
            </a>
            </div>

            <TwoColumnImg 
                imgSrcOne={'CreativePrompts/CreativePrompts_Design-A.jpg'}
                imgSrcTwo={'CreativePrompts/CreativePrompts_Design-B.jpg'}
                srcOneAlt={'draw screen design'}
                srcTwoAlt={'act screen design'}
            />

            <TwoColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_Design-C.jpg'}
                imgSrcTwo={'CreativePrompts/CreativePrompts_Design-D.jpg'}
                srcOneAlt={'Story screen design'}
                srcTwoAlt={'Saved screen design'}
            />

            <SubSectionHeader paddingTop={70}>STICKERSHEET</SubSectionHeader>
            <SectionText>I began creating a stickersheet of colors, typography, icons, navigation, components, and their states. </SectionText>

            <SingleColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_Stickersheet-A.jpg'}
                srcOneAlt={'stickersheet of color, typography, and navigation'}
                propClass={'fullimg'}
            />

            <SingleColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_Stickersheet-B.jpg'}
                srcOneAlt={'stickersheet of components used'}
                propClass={'fullimg'}
            />
            
            <SectionHeader paddingTop={70}>Desktop Design</SectionHeader>  
            <SubSectionHeader paddingTop={40}>PROGRESSIVE ENHANCEMENT</SubSectionHeader>
            <SectionText>With a progressive enhancement approach, I began working on desktop. There were three important decisions when sketching the desktop layout.</SectionText>

            <SingleColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_Desktop-Wireframe.jpg'}
                srcOneAlt={'Desktop wireframe'}
                propClass={'fullimg'}
            />

            <SectionText>
                <BoldSectionText>Navigation</BoldSectionText>{'\n\r'}
                With so much horizontal space, I landed on a sticky, left vertical menu. This would allow for visual space for the next big change, vertical scrolling. </SectionText>
            <SectionText>   
                <BoldSectionText>Vertical Scrolling</BoldSectionText>{'\n\r'}
                This felt like the most natural interaction for desktop. With the menu on the left side, this allowed free space for the cards to scroll in/out of view without any complicated overlap. </SectionText>
            <SectionText>
                <BoldSectionText>No overlapping elements</BoldSectionText>{'\n\r'}
                This felt like the most natural interaction for desktop. With the menu on the left side, this allowed free space for the cards to scroll in/out of view without any complicated overlap.  {'\n\r'}</SectionText>

            <div className='LinkBtnWrapper'>
            <a className='LinkBtn' href="https://www.figma.com/proto/L3LXhiYvDiWrnKYYdzIZIM/CoffeeHouse?node-id=312%3A884&scaling=scale-down&page-id=288%3A687&starting-point-node-id=312%3A884&show-proto-sidebar=1" target="_blank" rel="noopener noreferrer"> 
            View High-Fi Desktop Prototype
            </a>
            </div>

            <SingleColumnImg
                imgSrcOne={'CreativePrompts/CreativePrompts_Desktop-Design.jpg'}
                srcOneAlt={'Desktop design'}
                propClass={'fullimg'}
            />


            <SectionHeader paddingTop={70}>Key Takeaway</SectionHeader>
            <SectionText>{'\u2022'} Explore other visual queues that might be able to replace arrows. In this case, drop shadows, scale, and animation interaction helped queue the user. </SectionText>
            <SectionText>{'\u2022'} Don’t assume a user knows what an icon does. Labeling icons helps clear up any confusion with functionality. </SectionText>
            <SectionText>{'\u2022'} Children are quick to explore, quick to recover, and don’t mind making mistakes. This may play into having a script for adults, and a separate one for children. </SectionText>
            <SectionText>{'\u2022'} Think about intuitive motions a user is inclined to do depending on the device they are using. This was especially important in designing responsively as some motions are more intuitive on handheld devices, rather than with a mouse.</SectionText>
            
            <SubSectionHeader paddingTop={70}>IMPACT</SubSectionHeader>
            <SectionText>During testing, 75% of participants laughed at these prompts. Although this isn’t a live app, I’m excited to see this had a positive effect on kids. After reading the prompts, kids actively began to adapt the phrase, laughing at alternatives from their imagination. It was fun to see how this spurred further exploratory creativity.</SectionText>
 
            <SectionHeader paddingTop={70}>Next Steps</SectionHeader>
            <SectionText>{'\u2022'} Consider expanding this idea into a game with teams, timers, etc. This will increase time on screen. But would it increase play time as well? </SectionText>
            <SectionText>{'\u2022'} Consider how to best auto generate these phrases with a developer and writer. </SectionText>
            <SectionText>{'\u2022'} Consider implementing ideas on how users can ‘save’ their work from these prompts. Include photo gallery to save drawings, video to record acting scenes, or voice recording to record storytelling. This will encourage sharing with others, and confidence as they look back to watch videos they made, or listen to their own voice recordings.  </SectionText>

           <Footer></Footer>
        </div>
    )
}
