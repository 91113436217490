import React from 'react'
import './Usability.css';
import { LinkContainer } from 'react-router-bootstrap';

export default function Usability() {
    return (
        <div>
            <h2>Usability</h2>
            <p>Hi ___. My name is ___, and i’m going to be walking you through this session today. Before we begin, I have some information for you, and I’m going to read it to make sure that I cover everything. You probably already have a good idea of why we asked you here, but let me go over it again briefly. We’re asking people to try using a Website that we’re working on so we can see whether it works as intended. The sessions should take about an hour. The first thing I want to make clear right away is that we’re testing the site, not you. You can’t do anything wrong here. In fact, this is probably the one place today where you don’t have to worry about making mistakes.  As you use the site, I’m going to ask you as much as possible to try to think out loud: to say what you’re looking at, what you’re trying to do, and what you’re thinking. This will be a bug help to us. Also please don’t worry that you’re going to hurt our feelings. We’re doing this to improve the site, so we need to hear your honest reactions.   If you have any questions as we go along, just ask them. I may not be able to answer them right away, since we’re interested in how people do when they don’t have someone sitting next to them to help. But if you still have any questions when we’re done I’ll try to answer them then. And if you need to take a break at any point , just let me know.</p>
            <div className='usabilityBtn'>
                <LinkContainer to='/cookingUsability' >
                    <p>Cooking Usability Test</p>
                </LinkContainer>
            </div>
        </div>
    )
}