import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Invitations.css';

export default class Invitations extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {
          isLoading: true
        }
      }

      renderImage(imageUrl)
      {
          return (
              <div className="col-6 spacingBtm" key={imageUrl.key}>
                  <div className="container">
                      <img alt={imageUrl.alt} src ={require('../media/' + imageUrl.img + ".png")} />
                  </div>
              </div>
          );
      }

    render() {
        return (
            <div className="invitations top">
                <div className="title"> 
                <Dropdown>
                        <Dropdown.Toggle className="dropdownTitle">
                        INVITATIONS
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <LinkContainer to="/branding">
                                <Dropdown.Item >BRANDING</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/motion">
                                <Dropdown.Item>MOTION</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/infographics">
                                <Dropdown.Item>INFOGRAPHICS</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/UxUi">
                                <Dropdown.Item>UI/UX</Dropdown.Item>
                            </LinkContainer>
                        </Dropdown.Menu>
                    </Dropdown>
                 </div>
                <div className="row">
                    {this.props.invites.map(imageUrl => this.renderImage(imageUrl))}
                </div>
            </div>
        )
    }
}
