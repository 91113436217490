import React from 'react'
import './TwoColumnImg.css';

export default function TwoColumnImg({
    imgSrcOne, 
    imgSrcTwo, 
    srcOneAlt,
    srcTwoAlt,
}) {
    return (
        <div className='twoColImgContainer'>
            <div className='twoColImg'>
                <img src={require('../media/' + imgSrcOne)} alt={srcOneAlt} />
            </div>
            <div className='twoColImg'>
                <img src={require("../media/" + imgSrcTwo)} alt={srcTwoAlt}/>
            </div>
        </div>
    )
}