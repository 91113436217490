import React, { useState } from 'react'
import './CookingUsability.css';
import home from '../media/Cooking/Home.png';
import recipe from '../media/Cooking/Recipe.png';
import recipeBox from '../media/Cooking/RecipeBox.png';
import search from '../media/Cooking/Search.png';
import shoppingList from '../media/Cooking/ShoppingList.png';
import stepByStep1 from '../media/Cooking/Stepbystep.png';
import stepByStep2 from '../media/Cooking/Stepbystep2.png';

const questions = {
    'question': [
        {
            'text': 'What do you think you are looking at?',
            'img': home,
        },
        {
            'text': 'Without using search, you want to find a dessert to make.',
            'img': home,
        },
        {
            'text': 'You want to find a recipe you previously saved. Where do you start looking?',
            'img': home,
        },
        {
            'text': 'What do you expect to find by clicking the button in the top left?',
            'img': home,
        },
        {
            'text': 'You like the Oatmeal chocolate chip cookie recipe and you want to be able to find it quickly in the future.',
            'img': recipe,
        },
        {
            'text': 'You made this cookie recipe and noticed it used too much butter. How can you remind yourself for the next time you make this recipe.',
            'img': recipe,
        },
        {
            'text': 'You are going to make extra of this cookie recipe so you can freeze some dough. You want to double the recipe.',
            'img': recipe,
        },
        {
            'text': 'You are just starting with this recipe and you want to follow it step by step.',
            'img': recipe,
        },
        {
            'text': 'You are planning on making this next week and are missing ingredients. You need to remember what you need.',
            'img': recipe,
        },
        {
            'text': 'What do you think you are looking at?',
            'img': stepByStep1,
        },
        {
            'text': 'You are done with step 1 and want to move on.',
            'img': stepByStep1,
        },
        {
            'text': 'You don’t remember how much butter and sugar you need to combine.',
            'img': stepByStep2,
        },
        {
            'text': 'You combined butter and sugar. Now you need to make sure you let it stand for 3 minutes.',
            'img': stepByStep2,
        },
        {
            'text': 'What are you looking at?',
            'img': shoppingList,
        },
        {
            'text': 'You are no longer making chocolate chip cookies.',
            'img': shoppingList,
        },
        {
            'text': 'You realized you don’t have old fashioned oats.',
            'img': shoppingList,
        },
        {
            'text': 'You also need butter at the store but it’s not on the list',
            'img': shoppingList,
        },
        {
            'text': 'Your significant other is going to the store and you want them to get everything on this list.',
            'img': shoppingList,
        },
        {
            'text': 'You are done with the shopping list and want to look for other recipes.',
            'img': shoppingList,
        },
        {
            'text': 'You have chicken and rice. You want to see what recipes you can make.',
            'img': search,
        },
        {
            'text': 'You want to find cookies that take less than an hour.',
            'img': search,
        },
        {
            'text': 'You want to find a pizza dough recipe.',
            'img': search,
        },
        {
            'text': 'You want to view all the dessert recipes you have saved',
            'img': recipeBox,
        },
        {
            'text': 'You are hosting a birthday party and you want to have all the recipes you are making in one place.',
            'img': recipeBox,
        }


    ]
}

export default function CookingUsability() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const length = questions.question.length;
    return (
        <div>
            <p>Question: {currentIndex + 1} of {length}</p>
            <div className='imgContainer'>
                <img src={questions.question[currentIndex].img} alt="" />
            </div>
            <div className='footer'>
                <div className='footerContainer'>
                    <div className='prevBtn'>
                        <button className='cycleBtn' onClick={() => {
                            if (currentIndex > 0 ) {
                                setCurrentIndex(currentIndex - 1)
                            }
                        }
                        }>
                            &#8592;
                    </button>
                    </div>
                    <div className='questionDiv'>
                        <p className='footerText'>{questions.question[currentIndex].text}</p>
                    </div>
                    <div className='nextBtn'>
                        <button className='cycleBtn' onClick={() => {
                            if (currentIndex < questions.question.length - 1) {
                                console.log('here');
                                setCurrentIndex(currentIndex + 1)
                            }
                        }} >
                            &#8594;
                    </button>
                    </div>
                </div>
            </div>
        </div>
    )
}