import React from 'react'
import './SubSectionHeader.css';

export default function SubSectionHeader({children, paddingTop = 0}) {

    const divStyle = {
        paddingTop: paddingTop,
    };
    return (
        <div style={divStyle} className='subSectionContainer'>
            <h4 className='subSectionText'>{children}</h4>
        </div>
    )
}
