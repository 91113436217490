const backgroundReducer = (state = {}, action) => {
    switch (action.type) {
        case 'CURRENT_BACKGROUND':
            return {
                currentBackground: action.payload
            }
        default:
            return state
    }
}
export default backgroundReducer;