const previousSubject = (state = {}, action) => {
    switch (action.type) {
        case 'PREVIOUS_SUBJECT':
            return {
                prevSubject: action.payload
            }
        default:
            return state
    }
}
export default previousSubject;