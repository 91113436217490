import React, { Component } from "react";
import { connect } from 'react-redux';
import { navBackground } from '../actions/navBackground';
import { Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import "./About.css";
import SubSectionHeader from "../components/text/SubSectionHeader";


class About extends Component {

  componentDidMount() {
    this.props.navBackground(false);
  }

  render() {
    return (
      <div className="About">
        <Container>
          <Row>
            <Col xs={12} sm={4}>
              <div>
                <img className="aboutImg" src={require('../media/Maggiecall.jpg')} alt="profile of Maggie"></img>
              </div>
            <div className='TextLinkBtn'>
              <a className='TextLinkBtn' href="https://www.linkedin.com/in/maggiecall/" target="_blank" rel="noopener noreferrer"> 
            Connect on LinkedIn
            </a>
            </div>

                <div>
                <LinkContainer to="/usability">
                  <div className='hidden'></div>
                </LinkContainer>
              </div>
            </Col>
            <Col xs={12} sm={8}>
              <div className="aboutText">
                <h2>Maggie is a UX designer, monochrome lover, and ends almost every meal with chocolate.</h2>
                <br></br>
                <p>With nine years of experience in graphic design, I began work on a proprietary SEO software tool, where I discovered my passion for UX design. I enjoyed enhancing the tool’s efficiency and making data-driven design decisions through user testing. This experience sparked my journey into UX, where I continue to embrace the ongoing challenge of understanding and meeting user needs.</p>
                
                <p>I’m passionate about how thoughtful design can enrich our daily lives. I thrive on projects that simplify complexity and enhance meaningful interactions. My drive for minimalism fuels my approach to creating clear and intuitive user experiences.</p>
             
                <p>My process includes user research, wireframing, prototyping, and usability testing. I take pride in my iterative design approach, tasteful UI design, and my openness to feedback. I believe that exceptional UX arises from navigating constraints and effectively communicating design choices. Integrity is fundamental to both my personal and professional life, and I see every project as an opportunity to learn, grow, and align user needs with business goals.</p>
               
                <p>Outside of work, you can find me reorganizing something (anything!), baking,  or exploring my newfound interest in landscape design. I frequently assist in one of my husband's many DIY projects around the house.I’m new to (and still scared of) mountain biking.  I also enjoy rock climbing, snowshoeing and a solid 72% cocoa bar. 
                </p>
                
                <div className='LinkBtnWrapper'>
                <a className='LinkBtn' href="https://drive.google.com/file/d/1-_NlhK-7_SphSrJpNWWRIOCzFrUVJPEE/view?usp=sharing" target="_blank" rel="noopener noreferrer"> 
                View Maggie's Resume
                </a>
                </div>
            <SubSectionHeader paddingTop={40}>TESTIMONIALS</SubSectionHeader>
            <p>"Maggie is thorough in research, iterations, documentation and validating decisions. She is a great self-starter and takes initiative. When the company was shifting teams, I picked maggie to move with me.” —Manager</p>
            <br></br>
            <p>"I wish we could clone this team [prod, eng, ux]. This team produces the most work in this entire suite.” —VP Product</p>
            <br></br>
            <p>"I’d be doing backflips if I could work with maggie again!” —Co-worker</p>
            <br></br>
            <p>"Maggie has provided the smoothest handoff from UX to engineering that we've had.” —Engineer</p>
            <br></br>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(null, { navBackground })(About);