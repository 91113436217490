import React, { Component } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import './Navigation.css';

class Navigation extends Component {

    render() {
        return (
            <div className="navDiv">
                <Navbar collapseOnSelect className="navStyle" expand="lg" variant="light">
                <LinkContainer to="/">
                    <Navbar.Brand className={this.props.background ? "smallBoxBackground" : "smallBox" }>MAGGIE CALL</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                    <NavDropdown className={this.props.background ? "hoverBoxDropBackground dropAxis" : "hoverBoxDrop dropAxis"} title="WORK" id="collapsible-nav-dropdown" >
                    <LinkContainer to="/UxUi">
                        <NavDropdown.Item className="dropItem">ux/ui</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/branding">
                        <NavDropdown.Item className="dropItem">branding</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/infographics">
                        <NavDropdown.Item className="dropItem">infographics</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/motion">
                        <NavDropdown.Item className="dropItem">motion</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/invitations">
                        <NavDropdown.Item className="dropItem">invitations</NavDropdown.Item>
                    </LinkContainer>
                    </NavDropdown>
                    <div className="divider"></div>
                    <LinkContainer to="/about">
                    <Nav.Link className={this.props.background ? "hoverBoxBackground" : "hoverBox" }>ABOUT</Nav.Link>
                    </LinkContainer>
                </Nav>
                </Navbar.Collapse>
                </Navbar>
          </div>
        )
    }
}

const mapStateToProps = state => ({
    background: state.currentBackground.currentBackground
});

export default connect(mapStateToProps)(Navigation)
