import React, { Component } from 'react'
import { connect } from 'react-redux';
import './ViewImage.css';
import { FiArrowLeft } from 'react-icons/fi';
//import { currentImage } from '../actions/currentImage';

class ViewImage extends Component {

    goBack(){
        this.props.history.goBack();
    }

    render()
    {

        return (
            <div className="imgContainer">
                <div className="btnDiv">
                    <button className="simpleBtn" onClick={() => this.goBack()}><FiArrowLeft/> back to  {this.props.prevSubject} </button>
                </div>
                <div>
                    <img alt="" src={require('../media/' + this.props.image)} /> 
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    image: state.currentImage.currentImg,
    prevSubject: state.previousSubject.prevSubject
});


export default connect(mapStateToProps)(ViewImage);