import React from 'react'
import './SingleColumnImg.css';

export default function SingleColumnImg({
    imgSrcOne, 
    srcOneAlt,
    propClass
}) {
    return (
        <div className='oneColImgContainer'>
            <div className={`oneColImg ${propClass}`}>
                <img src={require('../media/' + imgSrcOne)} alt={srcOneAlt} />
            </div>
        </div>
    )
}