import React from 'react'
import './SectionHeader.css';

export default function SectionHeader({children, 
    paddingTop = 0,
    textAlign = 'left',
}) {
    const divStyle = {
        paddingTop: paddingTop,
        textAlign: textAlign,
    }
    return (
        <div style={divStyle} className='sectionHeaderContainer'>
            <h1 className='sectionHeaderText'>{children}</h1>
        </div>
    )
}
