import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import SectionText from '../components/text/SectionText';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './UxUi.css';

export default function UxUi() {

    return (
        <div className='container'>
            <div className="infographics top">
                <div className="title">
                    <Dropdown>
                        <Dropdown.Toggle className="dropdownTitle">
                            UX/UI
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <LinkContainer to="/branding">
                                <Dropdown.Item >BRANDING</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/motion">
                                <Dropdown.Item>MOTION</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/invitations">
                                <Dropdown.Item>INVITATIONS</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/infographics">
                                <Dropdown.Item>INFOGRAPHICS</Dropdown.Item>
                            </LinkContainer>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="uxMainContainer">
            <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Migrations</SectionHeader>
                        <SectionText textAlign={'center'} justifyContent={'center'}>Enabling client self-service and reducing downtime by 97%.</SectionText>
                            <LinkContainer to="/migrations">
                                <img className="imgHover image" onClick={() => { }} alt={'go to migrations ux ui page'} src={require('../media/Migrations/SIMmock.jpg')} />
                            </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Migrations UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Homebody</SectionHeader>
                        <SectionText textAlign={'center'} justifyContent={'center'}>A resident-centric app boosting engagement and revenue</SectionText>
                            <LinkContainer to="/homeBody">
                                <img className="imgHover image" onClick={() => { }} alt={'go to Homebody ux ui page'} src={require('../media/Homebody/Hb_FeatureThumb.jpg')} />
                            </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Homebody UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Iconography</SectionHeader>
                        <SectionText textAlign={'center'} justifyContent={'center'}>Led a team in the redesign of a 400+ icon library.</SectionText>
                        <LinkContainer to="/iconography">
                            <img className="imgHover image" onClick={() => { }} alt={'go to iconography ux ui page'} src={require('../media/Iconography/Icon-FeatureThumb.png')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see iconography UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Class Management</SectionHeader>
                        <SectionText textAlign={'center'} justifyContent={'center'}>Enabling instructors to manage classes more efficiently and scale.</SectionText>
                        <LinkContainer to="/classManagement">
                            <img className="imgHover image" onClick={() => { }} alt={'go to class management ux ui page'} src={require('../media/ClassMan/HIGH_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Class Management UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Creative Prompts</SectionHeader>
                        <SectionText textAlign={'center'} justifyContent={'center'}>Encouraging imaginative play beyond the screen for kids.</SectionText>
                        <LinkContainer to="/CreativePrompts">
                            <img className="imgHover image" onClick={() => { }} alt={'go to creative prompts ux ui page'} src={require('../media/CreativePrompts/CreativePrompts_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Creative Prompts UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Climbing Partner Finder</SectionHeader>
                        <SectionText textAlign={'center'} justifyContent={'center'}>Enabling climbers to find rock climbing partners.</SectionText>

                        <LinkContainer to="/ClimbingPartnerFinder">
                            <img className="imgHover image" onClick={() => { }} alt={'go to climbing partner ux ui page'} src={require('../media/ClimbingPartner/ClimbingPartner_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Climbing Partner Website UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Cooked</SectionHeader>
                        <SectionText textAlign={'center'} justifyContent={'center'}>Creating a whitelabel app solution for Wordpress bloggers.</SectionText>
                        <LinkContainer to="/cookedUxUi">
                            <img className="imgHover image" onClick={() => { }} alt={'go to cooked ux ui page'} src={require('../media/Cooked/Cooked_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Cooked UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Honed</SectionHeader>
                        <SectionText textAlign={'center'} justifyContent={'center'}>Customized hangboard training for climbers. </SectionText>
                        <LinkContainer to="/honedUxUi">
                            <img className="imgHover image" onClick={() => { }} alt={'go to honed ux ui page'} src={require('../media/Honed/Honed_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Honed UX/UI</div>
                        </div>
                    </div>
                </div>





            </div>
        </div>
    )
}
